import { put, all, call, take, actionChannel } from 'redux-saga/effects';
import { api } from '../helpers/api';

//login
function* fetchsignin(data) {
  const usersignin = yield api.postWithPayload(
    '/api/auth/signin',
    data
  );
  yield put({ type: 'SIGNIN_RESPONSE', usersignin });
}
function* actionWatcher() {
  const requestlogin = yield actionChannel('login');
  while (true) {
    const { data } = yield take(requestlogin);
    yield call(fetchsignin, data);
  }
}


//req otp
function* reqotp(data) {
  const otp_req_res = yield api.postWithPayload(
    '/api/auth/otp-request',
    data
  );
  yield put({ type: 'otprequest_response', otp_req_res });
}
function* actionWatcher_otp_req() {
  const request_otp = yield actionChannel('requestotp');
  while (true) {
    const { data } = yield take(request_otp);
    yield call(reqotp, data);
  }
}


//validate otp
function* valotp(data) {
  const otp_val_res = yield api.postWithPayload(
    '/api/auth/otp-validate',
    data
  );
  yield put({ type: 'validateotp_response', otp_val_res });
}
function* actionWatcher_val_otp_req() {
  const val_request_otp = yield actionChannel('validateotp');
  while (true) {
    const { data } = yield take(val_request_otp);
    yield call(valotp, data);
  }
}

//logout 
function* logout(data) {
  const logout_res = yield api.postWithPayloadwithToken(
    '/api/auth/signout',
    data
  );
  yield put({ type: 'logout_response', logout_res });
}
function* actionWatcher_logout() {
  const request_logout = yield actionChannel('logout');
  while (true) {
    const { data } = yield take(request_logout);
    yield call(logout, data);
  }
}


//custom notification send 
function* sendFunCall(data) {
  const customNotification_res = yield api.postWithPayloadwithToken(
    '/api/custom-notifications/send',
    data
  );
  yield put({ type: 'sendNotifications_response', customNotification_res });
}
function* actionWatcher_customnotification() {
  const request_customNotification = yield actionChannel('customNotificationSend');
  while (true) {
    const { data } = yield take(request_customNotification);
    yield call(sendFunCall, data);
  }
}


//user Management Starts Here
//user management list 
function* usermanagementlist(data) {
  const usermanagement_res = yield api.postWithPayloadwithToken(
    '/api/users/list',
    data
  );
  yield put({ type: 'usermanagementlist_response', usermanagement_res });
}
function* actionWatcher_user_manage_list() {
  const request_usermanagementlist = yield actionChannel('usermanagementlist');
  while (true) {
    const { data } = yield take(request_usermanagementlist);
    yield call(usermanagementlist, data);
  }
}

//inactive users list 
function* inactiveUserslist(data) {
  const inactive_users_res = yield api.postWithPayloadwithToken(
    '/api/mis/inactive-users',
    data
  );
  yield put({ type: 'inactiveUserslist_response', inactive_users_res });
}
function* actionWatcher_inactive_user_manage_list() {
  const request_inactiveUserslist = yield actionChannel('inactiveUserslist');
  while (true) {
    const { data } = yield take(request_inactiveUserslist);
    yield call(inactiveUserslist, data);
  }
}

//export inactive users 
function* inactiveUsersExport(data) {
  const inactiveUsersExport_res = yield api.postWithPayloadwithToken(
    '/api/mis/inactive-users/export', data);
  yield put({ type: 'inactiveUsersExport_response', inactiveUsersExport_res });
}
function* actionWatcher_inactiveUsers_Export() {
  const inactiveUsersExport_req = yield actionChannel('inactiveUsersExport');
  while (true) {
    const { data } = yield take(inactiveUsersExport_req);
    yield call(inactiveUsersExport, data);
  }
}

//Zero Stock Product list 
function* zeroStockList(data) {
  const zeroStockList_res = yield api.postWithPayloadwithToken(
    '/api/products/zero-stock-products',
    data
  );
  yield put({ type: 'zeroStockList_response', zeroStockList_res });
}
function* actionWatcher_zeroStock_list() {
  const request_zeroStockList = yield actionChannel('zeroStockList');
  while (true) {
    const { data } = yield take(request_zeroStockList);
    yield call(zeroStockList, data);
  }
}

//Zero Stock Product export 
function* zeroStockExport(data) { 
  const zeroStockExport_res = yield api.postWithPayloadwithToken(
    '/api/products/zero-stock-products/export',
    data
  );
  yield put({ type: 'zeroStockExport_response', zeroStockExport_res });
}
function* actionWatcher_zeroStock_Export() {
  const request_zeroStockExport = yield actionChannel('zeroStockExport');
  while (true) {
    const { data } = yield take(request_zeroStockExport);
    yield call(zeroStockExport, data);
  }
}

//inactive customers list 
function* inactiveCustomerslist(data) {
  const inactive_customers_res = yield api.postWithPayloadwithToken(
    '/api/mis/inactive-customers',
    data
  );
  yield put({ type: 'inactiveCustomerslist_response', inactive_customers_res });
}
function* actionWatcher_inactive_customers_manage_list() {
  const request_inactiveCustomerslist = yield actionChannel('inactiveCustomerslist');
  while (true) {
    const { data } = yield take(request_inactiveCustomerslist);
    yield call(inactiveCustomerslist, data);
  }
}

//export inactive customer 
function* inactiveCustomersExport(data) {
  const inactiveCustomersExport_res = yield api.postWithPayloadwithToken(
    '/api/mis/inactive-customers/export',
    data
  );
  yield put({ type: 'inactiveCustomersExport_response', inactiveCustomersExport_res });
}
function* actionWatcher_inactiveCustomers_Export() {
  const inactiveCustomersExport_req = yield actionChannel('inactiveCustomersExport');
  while (true) {
    const { data } = yield take(inactiveCustomersExport_req);
    yield call(inactiveCustomersExport, data);
  }
}

//user management create 
function* usermanagementcreate(data) {
  const user_create_res = yield api.postWithPayloadwithToken(
    '/api/users/create',
    data
  );
  yield put({ type: 'usermanagecreate_response', user_create_res });
}
function* actionWatcher_user_manage_create() {
  const request_usermanagementcreate = yield actionChannel('usermanagecreate');
  while (true) {
    const { data } = yield take(request_usermanagementcreate);
    yield call(usermanagementcreate, data);
  }
}

//get single user
function* usersingle(data) {
  const user_single = yield api.postWithPayloadwithToken(
    '/api/users/get/' + data
  );
  yield put({ type: 'usersingle_response', user_single });
}
function* actionWatcher_single_user() {
  const single_user = yield actionChannel('usersingle');
  while (true) {
    const { data } = yield take(single_user);
    yield call(usersingle, data);
  }
}

//user management update
function* usermanageupdate(data, id) {
  const user_update = yield api.postWithPayloadwithToken(
    '/api/users/update/' + id,
    data
  );
  yield put({ type: 'usermanageupdate_response', user_update });
}
function* actionWatcher_user_manage_update() {
  const request_usermanagementupdate = yield actionChannel('usermanageupdate');
  while (true) {
    const { data, id } = yield take(request_usermanagementupdate);
    yield call(usermanageupdate, data, id);
  }
}


//user delete 
function* deleteuser(item_id) {
  const user_delete = yield api.postWithPayloadwithToken(
    '/api/users/delete/' + item_id
  );
  yield put({ type: 'delete_user_response', user_delete });
}
function* actionWatcherdeleteuser() {
  const res_delete = yield actionChannel('delete_user');
  while (true) {
    const { item_id } = yield take(res_delete);
    yield call(deleteuser, item_id);
  }
}

//export customer 
function* userExportCall(data) {
  const userExport_res = yield api.postWithPayloadwithToken(
    '/api/users/export', data);
  yield put({ type: 'userExport_response', userExport_res });
}
function* actionWatcher_userExport() {
  const userExport_req = yield actionChannel('userExport');
  while (true) {
    const { data } = yield take(userExport_req);
    yield call(userExportCall, data);
  }
}


//agent Search
function* agentsearchlist(data) {
  const agent_response_search = yield api.postWithPayloadwithToken(
    '/api/users/search',
    data
  );
  yield put({ type: 'agentsearch_response', agent_response_search });
}
function* actionWatcher_agent_search() {
  const request_agentsearchlist = yield actionChannel('agent_search');
  while (true) {
    const { data } = yield take(request_agentsearchlist);
    yield call(agentsearchlist, data);
  }
}



//user active manage
function* useractive(data, id) {
  const useractive_update = yield api.postWithPayloadwithToken(
    '/api/users/update/active/' + id,
    data
  );
  yield put({ type: 'useractive_response', useractive_update });
}
function* actionWatcher_user_active_update() {
  const request_useractiveupdate = yield actionChannel('useractive');
  while (true) {
    const { data, id } = yield take(request_useractiveupdate);
    yield call(useractive, data, id);
  }
}



//agent Search
function* user(data) {
  const user_response_search = yield api.postWithPayloadwithToken(
    '/api/users/search',
    data
  );
  yield put({ type: 'user_response', user_response_search });
}
function* actionWatcher_user_search() {
  const request_usersearchlist = yield actionChannel('user');
  while (true) {
    const { data } = yield take(request_usersearchlist);
    yield call(user, data);
  }
}
// User Management Ends Here 

//UOM Management starts here

function* uommanagementlist(data) {
  const uommanagement_res = yield api.postWithPayloadwithToken(
    '/api/uom/list',
    data
  );
  yield put({ type: 'uommanagementlist_response', uommanagement_res });
}
function* actionWatcher_uom_manage_list() {
  const request_uommanagementlist = yield actionChannel('uommanagementlist');
  while (true) {
    const { data } = yield take(request_uommanagementlist);
    yield call(uommanagementlist, data);
  }
}


//uom management create 

function* uommanagementcreate(data) {
  const uom_create_res = yield api.postWithPayloadwithToken(
    '/api/uom/create',
    data
  );
  yield put({ type: 'uommanagecreate_response', uom_create_res });
}
function* actionWatcher_uom_manage_create() {
  const request_uommanagementcreate = yield actionChannel('uommanagecreate');
  while (true) {
    const { data } = yield take(request_uommanagementcreate);
    yield call(uommanagementcreate, data);
  }
}

//uom delete 

function* deleteuom(item_id) {
  const uom_delete = yield api.postWithPayloadwithToken(
    '/api/uom/delete/' + item_id
  );
  yield put({ type: 'delete_uom_response', uom_delete });
}
function* actionWatcherdeleteuom() {
  const res_delete = yield actionChannel('delete_uom');
  while (true) {
    const { item_id } = yield take(res_delete);
    yield call(deleteuom, item_id);
  }
}

//get single uom

function* uomsingle(data) {
  const uom_single = yield api.postWithPayloadwithToken(
    '/api/uom/get/' + data
  );
  yield put({ type: 'uomsingle_response', uom_single });
}
function* actionWatcher_single_uom() {
  const single_uom = yield actionChannel('uomsingle');
  while (true) {
    const { data } = yield take(single_uom);
    yield call(uomsingle, data);
  }
}

//uom management update
function* uommanageupdate(data, id) {
  const uom_update = yield api.postWithPayloadwithToken(
    '/api/uom/update/' + id,
    data
  );
  yield put({ type: 'uommanageupdate_response', uom_update });
}
function* actionWatcher_uom_manage_update() {
  const request_uommanagementupdate = yield actionChannel('uommanageupdate');
  while (true) {
    const { data, id } = yield take(request_uommanagementupdate);
    yield call(uommanageupdate, data, id);
  }
}

function* uomsearchlist(data) {
  const uom_response_search = yield api.postWithPayloadwithToken(
    '/api/uom/search',
    data
  );
  yield put({ type: 'uomsearch_response', uom_response_search });
}
function* actionWatcher_uom_search() {
  const request_uomsearchlist = yield actionChannel('uom_search');
  while (true) {
    const { data } = yield take(request_uomsearchlist);
    yield call(uomsearchlist, data);
  }
}

//uom product search
function* uom_product_search(data) {
  const uom_product_search_res = yield api.postWithPayloadwithToken(
    '/api/uom/get-by-product',
    data
  );
  yield put({ type: 'uom_product_search_response', uom_product_search_res });
}
function* actionWatcher_uom_product_search() {
  const request_uomsearchproduct = yield actionChannel('uom_product_search');
  while (true) {
    const { data } = yield take(request_uomsearchproduct);
    yield call(uom_product_search, data);
  }
}

//UOM Management ends here

//order failed List
function* orderFailedList(data) {
  const orderFailedList_res = yield api.postWithPayloadwithToken(
    '/api/orders/order-failed/list',
    data
  );
  yield put({ type: 'orderFailedList_response', orderFailedList_res });
}
function* actionWatcher_orderFailed_list() {
  const request_orderFailedList = yield actionChannel('orderFailedList');
  while (true) {
    const { data } = yield take(request_orderFailedList);
    yield call(orderFailedList, data);
  }
}


//commission Management starts here

function* commissionList(data) {
  const commission_res = yield api.postWithPayloadwithToken(
    '/api/commission/list',
    data
  );
  yield put({ type: 'commissionList_response', commission_res });
}
function* actionWatcher_commission_list() {
  const request_commissionList = yield actionChannel('commissionList');
  while (true) {
    const { data } = yield take(request_commissionList);
    yield call(commissionList, data);
  }
}


//commission delete 

function* commissionDelete(item_id) {
  const commission_delete = yield api.postWithPayloadwithToken(
    '/api/commission/delete/' + item_id
  );
  yield put({ type: 'delete_commission_response', commission_delete });
}
function* actionWatchercommissionDelete() {
  const res_delete = yield actionChannel('delete_commission');
  while (true) {
    const { item_id } = yield take(res_delete);
    yield call(commissionDelete, item_id);
  }
}

//get single commission

function* commissionSingle(item_id, data) {
  // console.log(data);
  const commission_single = yield api.postWithPayloadwithToken(
    '/api/commission/get/' + item_id, data
  );
  yield put({ type: 'commissionSingle_response', commission_single });
}
function* actionWatcher_single_commission() {
  const single_commission = yield actionChannel('commissionSingle');
  while (true) {
    const { item_id, data } = yield take(single_commission);
    yield call(commissionSingle, item_id, data);
  }
}

//commission Management ends here



//user hierarchy starts here

function* userHierarchyList(data) {
  const userHierarchyList_res = yield api.postWithPayloadwithToken(
    '/api/user-hierarchy/list',
    data
  );
  yield put({ type: 'userHierarchyList_response', userHierarchyList_res });
}
function* actionWatcher_userHierarchyList() {
  const request_userHierarchyList = yield actionChannel('userHierarchyList');
  while (true) {
    const { data } = yield take(request_userHierarchyList);
    yield call(userHierarchyList, data);
  }
}


//user hierarchy create 

function* userHierarchyCreate(data) {
  const userHierarchyCreate_res = yield api.postWithPayloadwithToken(
    '/api/user-hierarchy/create',
    data
  );
  yield put({ type: 'userHierarchyCreate_response', userHierarchyCreate_res });
}
function* actionWatcher_userHierarchy_create() {
  const request_userHierarchy = yield actionChannel('userHierarchyCreate');
  while (true) {
    const { data } = yield take(request_userHierarchy);
    yield call(userHierarchyCreate, data);
  }
}

//user Hierarchy delete 

function* deleteUserHierarchy(item_id) {
  const userHierarchyDelete_res = yield api.postWithPayloadwithToken(
    '/api/user-hierarchy/delete/' + item_id
  );
  yield put({ type: 'userHierarchyDelete_response', userHierarchyDelete_res });
}
function* actionWatcher_delete_userHierarchy() {
  const res_user_hierarchy_delete = yield actionChannel('userHierarchyDelete');
  while (true) {
    const { item_id } = yield take(res_user_hierarchy_delete);
    yield call(deleteUserHierarchy, item_id);
  }
}

//get single userHierarchy

function* userHierarchySingle(data) {
  const userHierarchySingle_res = yield api.postWithPayloadwithToken(
    '/api/user-hierarchy/get/' + data
  );
  yield put({ type: 'userHierarchySingle_response', userHierarchySingle_res });
}
function* actionWatcher_single_userHierarchy() {
  const single_userHierarchy = yield actionChannel('userHierarchySingle');
  while (true) {
    const { data } = yield take(single_userHierarchy);
    yield call(userHierarchySingle, data);
  }
}

//user hierarchy update
function* userHierarchyUpdate(data, id) {
  const userHierarchyUpdate_res = yield api.postWithPayloadwithToken(
    '/api/user-hierarchy/update/' + id,
    data
  );
  yield put({ type: 'userHierarchyUpdate_response', userHierarchyUpdate_res });
}
function* actionWatcher_user_hierarchy_update() {
  const request_userHierarchyUpdate = yield actionChannel('userHierarchyUpdate');
  while (true) {
    const { data, id } = yield take(request_userHierarchyUpdate);
    yield call(userHierarchyUpdate, data, id);
  }
}

//user hierarchy ends here



//Zone Management Starts Here

// Zone Management List
function* zonemanagementlist(data) {
  const zonemanagement_res = yield api.postWithPayloadwithToken(
    '/api/zones/list',
    data
  );
  yield put({ type: 'zonemanagementlist_response', zonemanagement_res });
}
function* actionWatcher_zone_manage_list() {
  const request_zonemanagementlist = yield actionChannel('zonemanagementlist');
  while (true) {
    const { data } = yield take(request_zonemanagementlist);
    yield call(zonemanagementlist, data);
  }
}


//Zone management create 
function* zonemanagementcreate(data) {
  const zone_create_res = yield api.postWithPayloadwithToken(
    '/api/zones/create',
    data
  );
  yield put({ type: 'zonemanagecreate_response', zone_create_res });
}
function* actionWatcher_zone_manage_create() {
  const request_zonemanagementcreate = yield actionChannel('zonemanagecreate');
  while (true) {
    const { data } = yield take(request_zonemanagementcreate);
    yield call(zonemanagementcreate, data);
  }
}

//zone delete 
function* deletezone(item_id) {
  const zone_delete = yield api.postWithPayloadwithToken(
    '/api/zones/delete/' + item_id
  );
  yield put({ type: 'delete_zone_response', zone_delete });
}
function* actionWatcherdeletezone() {
  const res_delete = yield actionChannel('delete_zone');
  while (true) {
    const { item_id } = yield take(res_delete);
    yield call(deletezone, item_id);
  }
}

//get single zone
function* zonesingle(data) {
  const zone_single = yield api.postWithPayloadwithToken(
    '/api/zones/get/' + data
  );
  yield put({ type: 'zonesingle_response', zone_single });
}
function* actionWatcher_single_zone() {
  const single_zone = yield actionChannel('zonesingle');
  while (true) {
    const { data } = yield take(single_zone);
    yield call(zonesingle, data);
  }
}


//Zone management update
function* zonemanageupdate(data, id) {
  const zone_update = yield api.postWithPayloadwithToken(
    '/api/zones/update/' + id,
    data
  );
  yield put({ type: 'zonemanageupdate_response', zone_update });
}
function* actionWatcher_zone_manage_update() {
  const request_zonemanagementupdate = yield actionChannel('zonemanageupdate');
  while (true) {
    const { data, id } = yield take(request_zonemanagementupdate);
    yield call(zonemanageupdate, data, id);
  }
}

//zone Search
function* zonesearchlist(data) {
  const zone_response_search = yield api.postWithPayloadwithToken(
    '/api/zones/search',
    data
  );
  yield put({ type: 'zonesearch_response', zone_response_search });
}
function* actionWatcher_zone_search() {
  const request_zonesearchlist = yield actionChannel('zone_search');
  while (true) {
    const { data } = yield take(request_zonesearchlist);
    yield call(zonesearchlist, data);
  }
}
//Zone Management Ends Here


//Region Managements Starts Here
// Region Management List
function* regionmanagementlist(data) {
  const regionmanagement_res = yield api.postWithPayloadwithToken(
    '/api/regions/list',
    data
  );
  yield put({ type: 'regionmanagementlist_response', regionmanagement_res });
}
function* actionWatcher_region_manage_list() {
  const request_regionmanagementlist = yield actionChannel('regionmanagementlist');
  while (true) {
    const { data } = yield take(request_regionmanagementlist);
    yield call(regionmanagementlist, data);
  }
}

//Region management create 
function* regionmanagementcreate(data) {
  const region_create_res = yield api.postWithPayloadwithToken(
    '/api/regions/create',
    data
  );
  yield put({ type: 'regionmanagecreate_response', region_create_res });
}
function* actionWatcher_region_manage_create() {
  const request_regionmanagementcreate = yield actionChannel('regionmanagecreate');
  while (true) {
    const { data } = yield take(request_regionmanagementcreate);
    yield call(regionmanagementcreate, data);
  }
}

//Region delete 
function* deleteregion(item_id) {
  const region_delete = yield api.postWithPayloadwithToken(
    '/api/regions/delete/' + item_id
  );
  yield put({ type: 'delete_region_response', region_delete });
}
function* actionWatcherdeleteregion() {
  const res_delete = yield actionChannel('delete_region');
  while (true) {
    const { item_id } = yield take(res_delete);
    yield call(deleteregion, item_id);
  }
}

//get single region
function* regionsingle(data) {
  const region_single = yield api.postWithPayloadwithToken(
    '/api/regions/get/' + data
  );
  yield put({ type: 'regionsingle_response', region_single });
}
function* actionWatcher_single_region() {
  const single_region = yield actionChannel('regionsingle');
  while (true) {
    const { data } = yield take(single_region);
    yield call(regionsingle, data);
  }
}


//region management update
function* regionmanageupdate(data, id) {
  const region_update = yield api.postWithPayloadwithToken(
    '/api/regions/update/' + id,
    data
  );
  yield put({ type: 'regionmanageupdate_response', region_update });
}
function* actionWatcher_region_manage_update() {
  const request_regionmanagementupdate = yield actionChannel('regionmanageupdate');
  while (true) {
    const { data, id } = yield take(request_regionmanagementupdate);
    yield call(regionmanageupdate, data, id);
  }
}


//Region Search
function* regionsearchlist(data) {
  const region_response_search = yield api.postWithPayloadwithToken(
    '/api/regions/search',
    data
  );
  yield put({ type: 'regionsearch_response', region_response_search });
}
function* actionWatcher_region_search() {
  const request_regionsearchlist = yield actionChannel('region_search');
  while (true) {
    const { data } = yield take(request_regionsearchlist);
    yield call(regionsearchlist, data);
  }
}

//Region Management Ends Here 



//Territory Management Starts Here
// Territory Management List
function* territorymanagementlist(data) {
  const territorymanagement_res = yield api.postWithPayloadwithToken(
    '/api/territories/list',
    data
  );
  yield put({ type: 'territorymanagementlist_response', territorymanagement_res });
}
function* actionWatcher_territory_manage_list() {
  const request_territorymanagementlist = yield actionChannel('territorymanagementlist');
  while (true) {
    const { data } = yield take(request_territorymanagementlist);
    yield call(territorymanagementlist, data);
  }
}

//territory management create 
function* territorymanagementcreate(data) {
  const territory_create_res = yield api.postWithPayloadwithToken(
    '/api/territories/create',
    data
  );
  yield put({ type: 'territorymanagecreate_response', territory_create_res });
}
function* actionWatcher_territory_manage_create() {
  const request_territorymanagementcreate = yield actionChannel('territorymanagecreate');
  while (true) {
    const { data } = yield take(request_territorymanagementcreate);
    yield call(territorymanagementcreate, data);
  }
}

//territory delete 
function* deleteterritory(item_id) {
  const territory_delete = yield api.postWithPayloadwithToken(
    '/api/territories/delete/' + item_id
  );
  yield put({ type: 'delete_territory_response', territory_delete });
}
function* actionWatcherdeleteterritory() {
  const res_delete = yield actionChannel('delete_territory');
  while (true) {
    const { item_id } = yield take(res_delete);
    yield call(deleteterritory, item_id);
  }
}

//get single Territory
function* territorysingle(data) {
  const territory_single = yield api.postWithPayloadwithToken(
    '/api/territories/get/' + data
  );
  yield put({ type: 'territorysingle_response', territory_single });
}
function* actionWatcher_single_territory() {
  const single_territory = yield actionChannel('territorysingle');
  while (true) {
    const { data } = yield take(single_territory);
    yield call(territorysingle, data);
  }
}


//territory management update
function* territorymanageupdate(data, id) {
  const territory_update = yield api.postWithPayloadwithToken(
    '/api/territories/update/' + id,
    data
  );
  yield put({ type: 'territorymanageupdate_response', territory_update });
}
function* actionWatcher_territory_manage_update() {
  const request_territorymanagementupdate = yield actionChannel('territorymanageupdate');
  while (true) {
    const { data, id } = yield take(request_territorymanagementupdate);
    yield call(territorymanageupdate, data, id);
  }
}


//territory Search
function* territorysearchlist(data) {
  const territory_response_search = yield api.postWithPayloadwithToken(
    '/api/territories/search',
    data
  );
  yield put({ type: 'territorysearch_response', territory_response_search });
}
function* actionWatcher_territory_search() {
  const request_territorysearchlist = yield actionChannel('territory_search');
  while (true) {
    const { data } = yield take(request_territorysearchlist);
    yield call(territorysearchlist, data);
  }
}
//Territory Management Ends Here

//Beats Management Starts Here
// Beats Management List
function* beatsmanagementlist(data) {
  const beatsmanagement_res = yield api.postWithPayloadwithToken(
    '/api/beats/list',
    data
  );
  yield put({ type: 'beatsmanagementlist_response', beatsmanagement_res });
}
function* actionWatcher_beats_manage_list() {
  const request_beatsmanagementlist = yield actionChannel('beatsmanagementlist');
  while (true) {
    const { data } = yield take(request_beatsmanagementlist);
    yield call(beatsmanagementlist, data);
  }
}

//Beats management create 
function* beatsmanagementcreate(data) {
  const beats_create_res = yield api.postWithPayloadwithToken(
    '/api/beats/create',
    data
  );
  yield put({ type: 'beatsmanagecreate_response', beats_create_res });
}
function* actionWatcher_beats_manage_create() {
  const request_beatsmanagementcreate = yield actionChannel('beatsmanagecreate');
  while (true) {
    const { data } = yield take(request_beatsmanagementcreate);
    yield call(beatsmanagementcreate, data);
  }
}

//beats delete 
function* deletebeats(item_id) {
  const beats_delete = yield api.postWithPayloadwithToken(
    '/api/beats/delete/' + item_id
  );
  yield put({ type: 'delete_beats_response', beats_delete });
}
function* actionWatcherdeletebeats() {
  const res_delete = yield actionChannel('delete_beats');
  while (true) {
    const { item_id } = yield take(res_delete);
    yield call(deletebeats, item_id);
  }
}


//get single beats
function* beatssingle(data) {
  const beats_single = yield api.postWithPayloadwithToken(
    '/api/beats/get/' + data
  );
  yield put({ type: 'beatssingle_response', beats_single });
}
function* actionWatcher_single_beats() {
  const single_beats = yield actionChannel('beatssingle');
  while (true) {
    const { data } = yield take(single_beats);
    yield call(beatssingle, data);
  }
}


//beats management update
function* beatsmanageupdate(data, id) {
  const beats_update = yield api.postWithPayloadwithToken(
    '/api/beats/update/' + id,
    data
  );
  yield put({ type: 'beatsmanageupdate_response', beats_update });
}
function* actionWatcher_beats_manage_update() {
  const request_beatsmanagementupdate = yield actionChannel('beatsmanageupdate');
  while (true) {
    const { data, id } = yield take(request_beatsmanagementupdate);
    yield call(beatsmanageupdate, data, id);
  }
}



//beat Search
function* beatsearchlist(data) {
  const beat_response_search = yield api.postWithPayloadwithToken(
    '/api/beats/search',
    data
  );
  yield put({ type: 'beatsearch_response', beat_response_search });
}
function* actionWatcher_beat_search() {
  const request_beatsearchlist = yield actionChannel('beat_search');
  while (true) {
    const { data } = yield take(request_beatsearchlist);
    yield call(beatsearchlist, data);
  }
}
//Beats management Ends Here 



//Brands Management Starts Here
// brand Management List
function* brandmanagementlist(data) {
  const brandmanagement_res = yield api.postWithPayloadwithToken(
    '/api/brands/list',
    data
  );
  yield put({ type: 'brandmanagementlist_response', brandmanagement_res });
}
function* actionWatcher_brand_manage_list() {
  const request_brandmanagementlist = yield actionChannel('brandmanagementlist');
  while (true) {
    const { data } = yield take(request_brandmanagementlist);
    yield call(brandmanagementlist, data);
  }
}


//brand management create 
function* brandmanagementcreate(data) {
  const brand_create_res = yield api.postWithPayloadwithToken(
    '/api/brands/create',
    data
  );
  yield put({ type: 'brandmanagecreate_response', brand_create_res });
}
function* actionWatcher_brand_manage_create() {
  const request_brandmanagementcreate = yield actionChannel('brandmanagecreate');
  while (true) {
    const { data } = yield take(request_brandmanagementcreate);
    yield call(brandmanagementcreate, data);
  }
}

//brand delete 
function* deletebrand(item_id) {
  const brand_delete = yield api.postWithPayloadwithToken(
    '/api/brands/delete/' + item_id
  );
  yield put({ type: 'delete_brand_response', brand_delete });
}
function* actionWatcherdeletebrand() {
  const res_delete = yield actionChannel('delete_brand');
  while (true) {
    const { item_id } = yield take(res_delete);
    yield call(deletebrand, item_id);
  }
}


//get single brand
function* brandsingle(data) {
  const brand_single = yield api.postWithPayloadwithToken(
    '/api/brands/get/' + data
  );
  yield put({ type: 'brandsingle_response', brand_single });
}
function* actionWatcher_single_brand() {
  const single_brand = yield actionChannel('brandsingle');
  while (true) {
    const { data } = yield take(single_brand);
    yield call(brandsingle, data);
  }
}


//brand management update
function* brandmanageupdate(data, id) {
  const brand_update = yield api.postWithPayloadwithToken(
    '/api/brands/update/' + id,
    data
  );
  yield put({ type: 'brandmanageupdate_response', brand_update });
}
function* actionWatcher_brand_manage_update() {
  const request_brandmanagementupdate = yield actionChannel('brandmanageupdate');
  while (true) {
    const { data, id } = yield take(request_brandmanagementupdate);
    yield call(brandmanageupdate, data, id);
  }
}


//brand Search
function* brandsearchlist(data) {
  const brand_response_search = yield api.postWithPayloadwithToken(
    '/api/brands/search',
    data
  );
  yield put({ type: 'brandsearch_response', brand_response_search });
}
function* actionWatcher_brand_search() {
  const request_brandsearchlist = yield actionChannel('brand_search');
  while (true) {
    const { data } = yield take(request_brandsearchlist);
    yield call(brandsearchlist, data);
  }
}
//Brands Management Ends Here 

// Admin User Start Here
 // Admin User List
function* adminuserlist(data) {
  const adminuser_res = yield api.postWithPayloadwithToken(
    '/api/admin-client/list',
    data
  );
  yield put({ type: 'adminuserlist_response', adminuser_res });
}
function* actionWatcher_admin_user_list() {
  const request_adminuserlist = yield actionChannel('adminuserlist');
  while (true) {
    const { data } = yield take(request_adminuserlist);
    yield call(adminuserlist, data);
  }
}


//Admin User create 
function* adminusercreate(data) {
  const adminuser_create_res = yield api.postWithPayloadwithToken(
    '/api/admin-client/create',
    data
  );
  yield put({ type: 'adminusercreate_response', adminuser_create_res });
}
function* actionWatcher_admin_user_create() {
  const request_adminusercreate = yield actionChannel('adminusercreate');
  while (true) {
    const { data } = yield take(request_adminusercreate);
    yield call(adminusercreate, data);
  }
}

//Admin User delete 
function* deleteadminuser(item_id) {
  const adminuser_delete = yield api.postWithPayloadwithToken(
    '/api/admin-client/delete/' + item_id
  );
  yield put({ type: 'delete_adminuser_response', adminuser_delete });
}
function* actionWatcher_admin_user_delete() {
  const res_adminuser = yield actionChannel('delete_adminuser');
  while (true) {
    const { item_id } = yield take(res_adminuser);
    yield call(deleteadminuser, item_id);
  }
}


//get Admin User
function* adminusersingle(data) {
  const adminuser_single = yield api.postWithPayloadwithToken(
    '/api/admin-client/get/' + data
  );
  yield put({ type: 'adminusersingle_response', adminuser_single });
}
function* actionWatcher_single_adminuser() {
  const single_adminuser = yield actionChannel('adminusersingle');
  while (true) {
    const { data } = yield take(single_adminuser);
    yield call(adminusersingle, data);
  }
}


//Admin User update
function* adminuserupdate(data, id) {
  const adminuser_update = yield api.postWithPayloadwithToken(
    '/api/admin-client/update/' + id,
    data
  );
  yield put({ type: 'adminuserupdate_response', adminuser_update });
}
function* actionWatcher_adminuser_update() {
  const request_adminuserupdate = yield actionChannel('adminuserupdate');
  while (true) {
    const { data, id } = yield take(request_adminuserupdate);
    yield call(adminuserupdate, data, id);
  }
}
// Admin User End Here 

// Roles management Start Here
 // Roles management List
 function* roleslist(data) {
  const roles_res = yield api.postWithPayloadwithToken(
    '/api/roles/list',
    data
  );
  yield put({ type: 'roleslist_response', roles_res });
}
function* actionWatcher_roles_list() {
  const request_roleslist = yield actionChannel('roleslist');
  while (true) {
    const { data } = yield take(request_roleslist);
    yield call(roleslist, data);
  }
}


//Roles management create 
function* rolescreate(data) {
  const roles_create_res = yield api.postWithPayloadwithToken(
    '/api/roles/create',
    data
  );
  yield put({ type: 'rolescreate_response', roles_create_res });
}
function* actionWatcher_roles_create() {
  const request_rolescreate = yield actionChannel('rolescreate');
  while (true) {
    const { data } = yield take(request_rolescreate);
    yield call(rolescreate, data);
  }
}

//Roles management delete 
function* deleteroles(item_id) {
  const roles_delete_res = yield api.postWithPayloadwithToken(
    '/api/roles/delete/' + item_id
  );
  yield put({ type: 'delete_roles_response', roles_delete_res });
}
function* actionWatcher_roles_delete() {
  const res_delete_roles = yield actionChannel('delete_roles');
  while (true) {
    const { item_id } = yield take(res_delete_roles);
    yield call(deleteroles, item_id);
  }
}


//Roles management User
function* rolessingle(data) {
  const roles_single_res = yield api.postWithPayloadwithToken(
    '/api/roles/get/' + data
  );
  yield put({ type: 'rolessingle_response', roles_single_res });
}
function* actionWatcher_single_roles() {
  const single_roles = yield actionChannel('rolessingle');
  while (true) {
    const { data } = yield take(single_roles);
    yield call(rolessingle, data);
  }
}


//Roles management update
function* rolesupdate(data, id) {
  const roles_update_res = yield api.postWithPayloadwithToken(
    '/api/roles/update/' + id,
    data
  );
  yield put({ type: 'rolesupdate_response', roles_update_res });
}
function* actionWatcher_roles_update() {
  const request_rolesupdate = yield actionChannel('rolesupdate');
  while (true) {
    const { data, id } = yield take(request_rolesupdate);
    yield call(rolesupdate, data, id);
  }
}

//Roles management User
function* rolenamelist(data) {
  const role_name_list_res = yield api.postWithPayloadwithToken(
    '/api/roles/role-name-list', data
  );
  yield put({ type: 'rolenamelist_response', role_name_list_res });
}
function* actionWatcher_role_name_list() {
  const req_rolenamelist = yield actionChannel('rolenamelist');
  while (true) {
    const { data } = yield take(req_rolenamelist);
    yield call(rolenamelist, data);
  }
}

//Roles active manage
function* roleactive(data, id) {
  const roleactive_update = yield api.postWithPayloadwithToken(
    '/api/roles/active/' + id,
    data
  );
  yield put({ type: 'roleactive_response', roleactive_update });
}
function* actionWatcher_role_active_update() {
  const request_roleactive = yield actionChannel('roleactive');
  while (true) {
    const { data, id } = yield take(request_roleactive);
    yield call(roleactive, data, id);
  }
}
// Roles management End Here 


//customer management Starts Here
// customer Management List
function* customermanagementlist(data) {
  const customermanagement_res = yield api.postWithPayloadwithToken(
    '/api/customers/list',
    data
  );
  yield put({ type: 'customermanagementlist_response', customermanagement_res });
}
function* actionWatcher_customer_manage_list() {
  const request_customermanagementlist = yield actionChannel('customermanagementlist');
  while (true) {
    const { data } = yield take(request_customermanagementlist);
    yield call(customermanagementlist, data);
  }
}


//customer management create 
function* customermanagementcreate(data) {
  const customer_create_res = yield api.postWithPayloadwithToken(
    '/api/customers/create',
    data
  );
  yield put({ type: 'customermanagecreate_response', customer_create_res });
}
function* actionWatcher_customer_manage_create() {
  const request_customermanagementcreate = yield actionChannel('customermanagecreate');
  while (true) {
    const { data } = yield take(request_customermanagementcreate);
    yield call(customermanagementcreate, data);
  }
}


//customer delete 
function* deletecustomer(item_id) {
  const customer_delete = yield api.postWithPayloadwithToken(
    '/api/customers/delete/' + item_id
  );
  yield put({ type: 'delete_customer_response', customer_delete });
}
function* actionWatcherdeletecustomer() {
  const res_delete = yield actionChannel('delete_customer');
  while (true) {
    const { item_id } = yield take(res_delete);
    yield call(deletecustomer, item_id);
  }
}

//get single customer
function* customersingle(data) {
  const customer_single = yield api.postWithPayloadwithToken(
    '/api/customers/get/' + data
  );
  yield put({ type: 'customersingle_response', customer_single });
}
function* actionWatcher_single_customer() {
  const single_customer = yield actionChannel('customersingle');
  while (true) {
    const { data } = yield take(single_customer);
    yield call(customersingle, data);
  }
}


//customer management update
function* customermanageupdate(data, id) {
  const customer_update = yield api.postWithPayloadwithToken(
    '/api/customers/update/' + id,
    data
  );
  yield put({ type: 'customermanageupdate_response', customer_update });
}
function* actionWatcher_customer_manage_update() {
  const request_customermanagementupdate = yield actionChannel('customermanageupdate');
  while (true) {
    const { data, id } = yield take(request_customermanagementupdate);
    yield call(customermanageupdate, data, id);
  }
}

//upload customer 
function* customerUpload(file) {
  const customerUploadresponse = yield api.postWithPayloadwithTokenandmultipart(
    '/api/customers/uploadCsv', file);
  yield put({ type: 'customerUpload_response', customerUploadresponse });
}
function* actionWatchercustomerUpload() {
  const requestproductup = yield actionChannel('customerUpload');
  while (true) {
    const { file } = yield take(requestproductup);
    yield call(customerUpload, file);
  }
}


//export customer 
function* customerexport(data) {
  const customerexportresponse = yield api.postWithPayloadwithToken(
    '/api/customers/export', data);
  yield put({ type: 'customerexport_response', customerexportresponse });
}
function* actionWatchercustomerexport() {
  const requestproductup = yield actionChannel('customerexport');
  while (true) {
    const { data } = yield take(requestproductup);
    yield call(customerexport, data);
  }
}

//customer Search
function* customersearch(data) {
  const customer_response_search = yield api.postWithPayloadwithToken(
    '/api/customers/search',
    data
  );
  yield put({ type: 'customersearch_response', customer_response_search });
}
function* actionWatcher_customer_search() {
  const request_customersearchlist = yield actionChannel('customer_search');
  while (true) {
    const { data } = yield take(request_customersearchlist);
    yield call(customersearch, data);
  }
}

function* customerdropdownlist(data) {
  const customerdropdownlist_res = yield api.postWithPayloadwithToken(
    '/api/customers/dropdown-list',
    data
  );
  yield put({ type: 'customerdropdownlist_response', customerdropdownlist_res });
}
function* actionWatcher_customerdropdownlist() {
  const request_customerdropdownlist = yield actionChannel('customerdropdownlist');
  while (true) {
    const { data } = yield take(request_customerdropdownlist);
    yield call(customerdropdownlist, data);
  }
}
//Customer Management Ends Here


//indirect customer 
// customer Management List
function* indirectcustomermanagementlist(data) {
  const indirectcustomermanagement_res = yield api.postWithPayloadwithToken(
    '/api/indirect/list',
    data
  );
  yield put({ type: 'indirectcustomermanagementlist_response', indirectcustomermanagement_res });
}
function* actionWatcher_indirectcustomer_manage_list() {
  const request_indirectcustomermanagementlist = yield actionChannel('indirectcustomermanagementlist');
  while (true) {
    const { data } = yield take(request_indirectcustomermanagementlist);
    yield call(indirectcustomermanagementlist, data);
  }
}

//indirect customer delete 
function* deleteindirectcustomer(item_id) {
  const indirectcustomer_delete = yield api.postWithPayloadwithToken(
    '/api/indirect/delete/' + item_id
  );
  yield put({ type: 'delete_indirectcustomer_response', indirectcustomer_delete });
}
function* actionWatcherdeleteindirectcustomer() {
  const res_indirectdelete = yield actionChannel('delete_indirectcustomer');
  while (true) {
    const { item_id } = yield take(res_indirectdelete);
    yield call(deleteindirectcustomer, item_id);
  }
}

//wholesaler Search
function* wholesalerSearch(data) {
  const wholesalerSearch_res = yield api.postWithPayloadwithToken(
    '/api/customers/list/customer/type',
    data
  );
  yield put({ type: 'wholesalerSearch_response', wholesalerSearch_res });
}
function* actionWatcher_wholesalerSearch() {
  const request_wholesalerSearch = yield actionChannel('wholesalerSearch');
  while (true) {
    const { data } = yield take(request_wholesalerSearch);
    yield call(wholesalerSearch, data);
  }
}

//indirect customer management create 
function* indirectcustomercreate(data) {
  const indirectcustomercustomer_create_res = yield api.postWithPayloadwithToken(
    '/api/indirect/create',
    data
  );
  yield put({ type: 'indirectcustomercreate_response', indirectcustomercustomer_create_res });
}
function* actionWatcher_indirectcustomer_manage_create() {
  const request_indirectcustomercreate = yield actionChannel('indirectcustomercreate');
  while (true) {
    const { data } = yield take(request_indirectcustomercreate);
    yield call(indirectcustomercreate, data);
  }
}

//get single indirectcustomer
function* indirectcustomersingle(data) {
  const indirectcustomer_single = yield api.postWithPayloadwithToken(
    '/api/indirect/get/' + data
  );
  yield put({ type: 'indirectcustomersingle_response', indirectcustomer_single });
}
function* actionWatcher_single_indirectcustomer() {
  const single_indirectcustomer = yield actionChannel('indirectcustomersingle');
  while (true) {
    const { data } = yield take(single_indirectcustomer);
    yield call(indirectcustomersingle, data);
  }
}


//indirect customer management update
function* indirectcustomermanageupdate(data, id) {
  const indirectcustomer_update = yield api.postWithPayloadwithToken(
    '/api/indirect/update/' + id,
    data
  );
  yield put({ type: 'indirectcustomermanageupdate_response', indirectcustomer_update });
}
function* actionWatcher_indirect_customer_manage_update() {
  const request_indirectcustomermanagementupdate = yield actionChannel('indirectcustomermanageupdate');
  while (true) {
    const { data, id } = yield take(request_indirectcustomermanagementupdate);
    yield call(indirectcustomermanageupdate, data, id);
  }
}

// Indirect Customer Upload
function* indirectcustomerUpload(file) {
  const indirectcustomerUploadresponse = yield api.postWithPayloadwithTokenandmultipart(
    '/api/indirect/uploadCsv', file);
  yield put({ type: 'indirectcustomerUpload_response', indirectcustomerUploadresponse });
}
function* actionWatcherindirectcustomerUpload() {
  const requestindirectup = yield actionChannel('indirectcustomerUpload');
  while (true) {
    const { file } = yield take(requestindirectup);
    yield call(indirectcustomerUpload, file);
  }
}

//export indirect customer 
function* indirectcustomerexport(data) {
  const indirectcustomerexportresponse = yield api.postWithPayloadwithToken(
    '/api/indirect/export', data);
  yield put({ type: 'indirectcustomerexport_response', indirectcustomerexportresponse });
}
function* actionWatcherindirectcustomerexport() {
  const requestindirectdl = yield actionChannel('indirectcustomerexport');
  while (true) {
    const { data } = yield take(requestindirectdl);
    yield call(indirectcustomerexport, data);
  }
}

function* indirectdropdownlist(data) {
  const indirectdropdownlist_res = yield api.postWithPayloadwithToken(
    '/api/indirect/dropdown-list',
    data
  );
  yield put({ type: 'indirectdropdownlist_response', indirectdropdownlist_res });
}
function* actionWatcher_indirectdropdownlist() {
  const request_indirectdropdownlist = yield actionChannel('indirectdropdownlist');
  while (true) {
    const { data } = yield take(request_indirectdropdownlist);
    yield call(indirectdropdownlist, data);
  }
}

//Product Management Starts Here
// product Management List
function* productmanagementlist(data) {
  const productmanagement_res = yield api.postWithPayloadwithToken(
    '/api/products/list',
    data
  );
  yield put({ type: 'productmanagementlist_response', productmanagement_res });
}
function* actionWatcher_product_manage_list() {
  const request_productmanagementlist = yield actionChannel('productmanagementlist');
  while (true) {
    const { data } = yield take(request_productmanagementlist);
    yield call(productmanagementlist, data);
  }
}


//product management create 
function* productmanagementcreate(data) {
  const product_create_res = yield api.postWithPayloadwithToken(
    '/api/products/create',
    data
  );
  yield put({ type: 'productmanagecreate_response', product_create_res });
}
function* actionWatcher_product_manage_create() {
  const request_productmanagementcreate = yield actionChannel('productmanagecreate');
  while (true) {
    const { data } = yield take(request_productmanagementcreate);
    yield call(productmanagementcreate, data);
  }
}

//product delete 
function* deleteproduct(item_id) {
  const product_delete = yield api.postWithPayloadwithToken(
    '/api/products/delete/' + item_id
  );
  yield put({ type: 'delete_product_response', product_delete });
}
function* actionWatcherdeleteproduct() {
  const res_delete = yield actionChannel('delete_product');
  while (true) {
    const { item_id } = yield take(res_delete);
    yield call(deleteproduct, item_id);
  }
}


//get single product
function* productsingle(data) {
  const product_single = yield api.postWithPayloadwithToken(
    '/api/products/get/' + data
  );
  yield put({ type: 'productsingle_response', product_single });
}
function* actionWatcher_single_product() {
  const single_product = yield actionChannel('productsingle');
  while (true) {
    const { data } = yield take(single_product);
    yield call(productsingle, data);
  }
}


//product management update
function* productmanageupdate(data, id) {
  const product_update = yield api.postWithPayloadwithToken(
    '/api/products/update/' + id,
    data
  );
  yield put({ type: 'productmanageupdate_response', product_update });
}
function* actionWatcher_product_manage_update() {
  const request_productmanagementupdate = yield actionChannel('productmanageupdate');
  while (true) {
    const { data, id } = yield take(request_productmanagementupdate);
    yield call(productmanageupdate, data, id);
  }
}


//upload product 
function* productUpload(file) {
  const productUploadresponse = yield api.postWithPayloadwithTokenandmultipart(
    '/api/products/uploadCsv', file);
  yield put({ type: 'productUpload_response', productUploadresponse });
}
function* actionWatcherproductUpload() {
  const requestproductup = yield actionChannel('productUpload');
  while (true) {
    const { file } = yield take(requestproductup);
    yield call(productUpload, file);
  }
}


//export product 
function* productexport(data) {
  const productexportresponse = yield api.postWithPayloadwithToken(
    '/api/products/export', data);
  yield put({ type: 'productexport_response', productexportresponse });
}
function* actionWatcherproductexport() {
  const requestproductup = yield actionChannel('productexport');
  while (true) {
    const { data } = yield take(requestproductup);
    yield call(productexport, data);
  }
}

function* productdropdownlist(data) {
  const productdropdownlist_res = yield api.postWithPayloadwithToken(
    '/api/products/dropdown-list',
    data
  );
  yield put({ type: 'productdropdownlist_response', productdropdownlist_res });
}
function* actionWatcher_productdropdownlist() {
  const request_productdropdownlist = yield actionChannel('productdropdownlist');
  while (true) {
    const { data } = yield take(request_productdropdownlist);
    yield call(productdropdownlist, data);
  }
}
//Product Management Ends Here 



//Company List  
function* companylist(data) {
  const companylist_res = yield api.postWithPayloadwithToken('/api/companies/list', data);
  yield put({ type: 'companylist_response', companylist_res });
}
function* actionWatcher_company_list() {
  const request_companylist = yield actionChannel('companylist');
  while (true) {
    const { data } = yield take(request_companylist);
    yield call(companylist, data);
  }
}



//upload stock 
function* stockUpload(file) {
  const stockUploadresponse = yield api.postWithPayloadwithTokenandmultipart(
    '/api/products/uploadStockCsv', file);
  yield put({ type: 'stockUpload_response', stockUploadresponse });
}
function* actionWatcherstockUpload() {
  const requestproductup = yield actionChannel('stockUpload');
  while (true) {
    const { file } = yield take(requestproductup);
    yield call(stockUpload, file);
  }
}

//export stock 
function* stockexport(data) {
  const stockexportresponse = yield api.postWithPayloadwithToken(
    '/api/products/exportStock', data);
  yield put({ type: 'stockexport_response', stockexportresponse });
}
function* actionWatcherstockexport() {
  const requeststockup = yield actionChannel('stockexport');
  while (true) {
    const { data } = yield take(requeststockup);
    yield call(stockexport, data);
  }
}


//order management starts here
// order Management List
function* ordermanagementlist(data) {
  const ordermanagement_res = yield api.postWithPayloadwithToken(
    '/api/orders/list',
    data
  );
  yield put({ type: 'ordermanagementlist_response', ordermanagement_res });
}
function* actionWatcher_order_manage_list() {
  const request_ordermanagementlist = yield actionChannel('ordermanagementlist');
  while (true) {
    const { data } = yield take(request_ordermanagementlist);
    yield call(ordermanagementlist, data);
  }
}

//get single Order
function* orderSingle(data) {
  const order_single = yield api.postWithPayloadwithToken(
    '/api/orders/get/' + data
  );
  yield put({ type: 'orderSingle_response', order_single });
}
function* actionWatcher_single_order() {
  const single_order = yield actionChannel('orderSingle');
  while (true) {
    const { data } = yield take(single_order);
    yield call(orderSingle, data);
  }
}

//Order delete
function* orderDelete(item_id) {
  const order_delete = yield api.postWithPayloadwithToken(
    '/api/orders/delete/' + item_id
  );
  yield put({ type: 'delete_order_response', order_delete });
}
function* actionWatcherorderDelete() {
  const retailerOrderdelete = yield actionChannel('delete_order');
  while (true) {
    const { item_id } = yield take(retailerOrderdelete);
    yield call(orderDelete, item_id);
  }
}

//Order delete
function* order_pdf_call(item_id) {
  const order_pdf_res = yield api.postWithPayloadwithToken(
    '/api/orders/export-pdf/' + item_id
  );
  yield put({ type: 'order_pdf_response', order_pdf_res });
}
function* actionWatcher_order_pdf() {
  const order_pdf_req = yield actionChannel('order_pdf');
  while (true) {
    const { item_id } = yield take(order_pdf_req);
    yield call(order_pdf_call, item_id);
  }
}

//Order No
function* order_no_list(data) {
  const order_no_list_res = yield api.postWithPayloadwithToken(
    '/api/orders/order-num-list', data
  );
  yield put({ type: 'order_no_list_response', order_no_list_res });
}
function* actionWatcher_order_no_list() {
  const reqOrderNoList = yield actionChannel('orderNoList');
  while (true) {
    const { data } = yield take(reqOrderNoList);
    yield call(order_no_list, data);
  }
}

//Order create
function* orderCreate(data) {
  const order_create = yield api.postWithPayloadwithToken(
    '/api/orders/create', data
  );
  yield put({ type: 'order_create_response', order_create });
}
function* actionWatcher_order_create() {
  const orderCreateData = yield actionChannel('orderCreate');
  while (true) {
    const { data } = yield take(orderCreateData);
    yield call(orderCreate, data);
  }
}


//order update
function* order_edit(data, id) {
  const order_update = yield api.postWithPayloadwithToken(
    '/api/orders/update/' + id,
    data
  );
  yield put({ type: 'orderUpdate_response', order_update });
}
function* actionWatcher_order_manage_update() {
  const request_order = yield actionChannel('orderUpdate');
  while (true) {
    const { data, id } = yield take(request_order);
    yield call(order_edit, data, id);
  }
}

//order reject
function* order_rejectFun(data, id) {
  const order_reject = yield api.postWithPayloadwithToken(
    '/api/orders/reject/' + id,
    data
  );
  yield put({ type: 'orderReject_response', order_reject });
}
function* actionWatcher_order_manage_reject() {
  const request_order = yield actionChannel('orderReject');
  while (true) {
    const { data, id } = yield take(request_order);
    yield call(order_rejectFun, data, id);
  }
}

//order Outstanding Days
function* OutstandingDays(id) {
  const remainingOutstandingDays_res = yield api.postWithPayloadwithToken('/api/orders/outstandingday/' + id);
  yield put({ type: 'remainingOutstandingDays_Response', remainingOutstandingDays_res });
}
function* actionWatcher_OutstandingDays() {
  const req_OutstandingDays = yield actionChannel('remainingOutstandingDays');
  while (true) {
    const { id } = yield take(req_OutstandingDays);
    yield call(OutstandingDays, id)
  }
}

//order product get

function* orderProduct(data) {
  const orderproduct_res = yield api.postWithPayloadwithToken(
    '/api/orders/order-product/get/' + data
  );
  yield put({ type: 'orderProduct_response', orderproduct_res });
}
function* actionWatcher_orderProduct() {
  const req_orderproduct = yield actionChannel('orderProduct');
  while (true) {
    const { data } = yield take(req_orderproduct);
    yield call(orderProduct, data);
  }
}

//order Outstanding Days
function* orderOutstandingDays(id) {
  const outstandingDaysResponse = yield api.postWithPayloadwithToken('/api/orders/get-orderdetails/' + id);
  yield put({ type: 'outstandingDays_Response', outstandingDaysResponse });
}
function* actionWatcher_orderOutstandingDays() {
  const requestOutstandingDays = yield actionChannel('outstandingDays');
  while (true) {
    const { id } = yield take(requestOutstandingDays);
    yield call(orderOutstandingDays, id)
  }
}



//order for route list
function* routeOrderList(data) {
  const routeOrder_res = yield api.postWithPayloadwithToken(
    '/api/orders/list-all-route',
    data
  );
  yield put({ type: 'routeOrdermanagementlist_response', routeOrder_res });
}
function* actionWatcher_routeOrder() {
  const request_routeOrder = yield actionChannel('routeOrdermanagementlist');
  while (true) {
    const { data } = yield take(request_routeOrder);
    yield call(routeOrderList, data);
  }
}

function* routeOrderList2(data) {
  const routeOrder_res2 = yield api.postWithPayloadwithToken(
    '/api/orders/list-all-route2',
    data
  );
  yield put({ type: 'routeOrdermanagementlist2_response', routeOrder_res2 });
}
function* actionWatcher_routeOrder2() {
  const request_routeOrder = yield actionChannel('routeOrdermanagementlist2');
  while (true) {
    const { data } = yield take(request_routeOrder);
    yield call(routeOrderList2, data);
  }
}


//export order 
function* orderexport(data) {
  const orderexportresponse = yield api.postWithPayloadwithToken(
    '/api/orders/export', data);
  yield put({ type: 'orderexport_response', orderexportresponse });
}
function* actionWatcherorderexport() {
  const requestorderup = yield actionChannel('orderexport');
  while (true) {
    const { data } = yield take(requestorderup);
    yield call(orderexport, data);
  }
}

//export order 
function* multipleOrderExport(data) {
  const multipleorderexportresponse = yield api.postWithPayloadwithToken(
    '/api/orders/multiple-export-pdf', data);
  yield put({ type: 'multipleorderexport_response', multipleorderexportresponse });
}
function* actionWatchermultipleorderexport() {
  const requestmultipleorderexport = yield actionChannel('multipleOrderExport');
  while (true) {
    const { data } = yield take(requestmultipleorderexport);
    yield call(multipleOrderExport, data);
  }
}

//order management ends here 

function* get_profile(profile_payload) {
  const profile_data_get = yield api.postWithPayloadwithToken(
    '/api/account/profile',
    profile_payload
  );
  yield put({ type: 'receive_profile_data', profile_data_get });
}
function* actionWatcherprofileget() {
  const requestprofile = yield actionChannel('get_profile');
  while (true) {
    const { profile_payload } = yield take(requestprofile);
    yield call(get_profile, profile_payload);
  }
}

// Users dashboard count
function* usersdashboard(data) {
  const usersdashboard_res = yield api.postWithPayloadwithToken(
    '/api/users/dashboardCounts',
    data
  );

  yield put({ type: 'usersdashboard_response', usersdashboard_res });
}
function* actionWatcher_usersdashboard() {
  const request_usersdashboard = yield actionChannel('usersdashboard');
  while (true) {
    const { data } = yield take(request_usersdashboard);
    yield call(usersdashboard, data);
  }
}

//dashboard top 10 product
function* product_top_10(data) {
  const product_top_10_res = yield api.postWithPayloadwithToken(
    '/api/mis/top10-products',
    data
  );

  yield put({ type: 'product_top_10_response', product_top_10_res });
}
function* actionWatcher_product_top_10() {
  const request_product_top_10 = yield actionChannel('product_top_10');
  while (true) {
    const { data } = yield take(request_product_top_10);
    yield call(product_top_10, data);
  }
}

//dashboard least 10 product
function* product_least_10(data) {
  const product_least_10_res = yield api.postWithPayloadwithToken(
    '/api/mis/least10-products',
    data
  );

  yield put({ type: 'product_least_10_response', product_least_10_res });
}
function* actionWatcher_product_least_10() {
  const request_least_10 = yield actionChannel('product_least_10');
  while (true) {
    const { data } = yield take(request_least_10);
    yield call(product_least_10, data);
  }
}

//dashboard top 10 customers
function* customers_top_10(data) {
  const customers_top_10_res = yield api.postWithPayloadwithToken(
    '/api/mis/top10-customers',
    data
  );

  yield put({ type: 'customers_top_10_response', customers_top_10_res });
}
function* actionWatcher_customers_top_10() {
  const request_customers_top_10 = yield actionChannel('customers_top_10');
  while (true) {
    const { data } = yield take(request_customers_top_10);
    yield call(customers_top_10, data);
  }
}

//dashboard customer visit
function* customers_visit(data) {
  const customers_visit_res = yield api.postWithPayloadwithToken(
    '/api/customers/visit-list',
    data
  );

  yield put({ type: 'customers_visit_response', customers_visit_res });
}
function* actionWatcher_customers_visit() {
  const request_customers_visit = yield actionChannel('customers_visit');
  while (true) {
    const { data } = yield take(request_customers_visit);
    yield call(customers_visit, data);
  }
}

//dashboard customer visit list export
function* customers_visit_export(data) {
  const customers_visit_export_res = yield api.postWithPayloadwithToken(
    '/api/customers/visit-export',
    data
  );

  yield put({ type: 'customers_visit_export_response', customers_visit_export_res });
}
function* actionWatcher_customers_visit_export() {
  const request_visit_export = yield actionChannel('customers_visit_export');
  while (true) {
    const { data } = yield take(request_visit_export);
    yield call(customers_visit_export, data);
  }
}

//dashboard least 10 customers
function* customers_least_10(data) {
  const customers_least_10_res = yield api.postWithPayloadwithToken(
    '/api/mis/least10-customers',
    data
  );

  yield put({ type: 'customers_least_10_response', customers_least_10_res });
}
function* actionWatcher_customers_least_10() {
  const request_least_10 = yield actionChannel('customers_least_10');
  while (true) {
    const { data } = yield take(request_least_10);
    yield call(customers_least_10, data);
  }
}

//dashboard top 10 orders
function* orders_top_10(data) {
  const orders_top_10_res = yield api.postWithPayloadwithToken(
    '/api/mis/top10-orders',
    data
  );

  yield put({ type: 'orders_top_10_response', orders_top_10_res });
}
function* actionWatcher_orders_top_10() {
  const request_orders_top_10 = yield actionChannel('orders_top_10');
  while (true) {
    const { data } = yield take(request_orders_top_10);
    yield call(orders_top_10, data);
  }
}

//dashboard least 10 orders
function* orders_least_10(data) {
  const orders_least_10_res = yield api.postWithPayloadwithToken(
    '/api/mis/least10-orders',
    data
  );

  yield put({ type: 'orders_least_10_response', orders_least_10_res });
}
function* actionWatcher_orders_least_10() {
  const request_least_10 = yield actionChannel('orders_least_10');
  while (true) {
    const { data } = yield take(request_least_10);
    yield call(orders_least_10, data);
  }
}
// stock managment
function* stockmanagementlist(data) {
  const stockmanagementlist_res = yield api.postWithPayloadwithToken(
    '/api/stocks/list',
    data
  );
  yield put({ type: 'stockmanagementlist_response', stockmanagementlist_res });
}
function* actionWatcher_stock_manage_list() {
  const request_stockmanagementlist = yield actionChannel('stockmanagementlist');
  while (true) {
    const { data } = yield take(request_stockmanagementlist);
    yield call(stockmanagementlist, data);
  }
}

function* stocksingle(data) {
  const stocksingle_res = yield api.postWithPayloadwithToken(
    '/api/stocks/get/' + data
  );
  yield put({ type: 'stocksingle_response', stocksingle_res });
}
function* actionWatcher_stocksingle() {
  const req_stocksingle = yield actionChannel('stocksingle');
  while (true) {
    const { data } = yield take(req_stocksingle);
    yield call(stocksingle, data);
  }
}

function* stockmanagementupdate(data, id) {
  const stock_update = yield api.postWithPayloadwithToken(
    '/api/stocks/update/' + id,
    data
  );
  yield put({ type: 'stockmanagementupdate_response', stock_update });
}
function* actionWatcher_stock_manage_update() {
  const request_stockmanagementupdate = yield actionChannel('stockmanagementupdate');
  while (true) {
    const { data, id } = yield take(request_stockmanagementupdate);
    yield call(stockmanagementupdate, data, id);
  }
}

//stock History

//list
function* stockHistory_list_call(data) {
  const stockHistory_list_res = yield api.postWithPayloadwithToken(
    '/api/stock-history/list',
    data
  );
  yield put({ type: 'stockHistory_list_response', stockHistory_list_res });
}
function* actionWatcher_stockHistory_list() {
  const stockHistory_req = yield actionChannel('stockHistory_list');
  while (true) {
    const { data } = yield take(stockHistory_req);
    yield call(stockHistory_list_call, data);
  }
}

//get single stock History
function* stockHistory_get_call(data) {
  const stockHistory_get_res = yield api.postWithPayloadwithToken(
    '/api/stock-history/get/' + data
  );
  yield put({ type: 'stockHistory_get_response', stockHistory_get_res });
}
function* actionWatcher_stockHistory_get() {
  const stockHistory_get_req = yield actionChannel('stockHistory_get');
  while (true) {
    const { data } = yield take(stockHistory_get_req);
    yield call(stockHistory_get_call, data);
  }
}


function* changePassword(data) {
  const changePassword_response = yield api.postWithPayloadwithToken(
    '/api/account/change-password',
    data
  );
  yield put({ type: 'receive_changePassword_data', changePassword_response });
}
function* actionWatcher_changepassword() {
  const request_changePassword = yield actionChannel('changePassword');
  while (true) {
    const { data } = yield take(request_changePassword);
    yield call(changePassword, data);
  }
}

//email config
function* callEmailConfig(data) {
  const changeEmailConfig_res = yield api.postWithPayloadwithToken(
    '/api/account/email-config',
    data
  );
  yield put({ type: 'receive_emailConfig_update', changeEmailConfig_res });
}
function* actionWatcher_email_config() {
  const request_updateEmailConfig = yield actionChannel('changeEmailConfig');
  while (true) {
    const { data } = yield take(request_updateEmailConfig);
    yield call(callEmailConfig, data);
  }
}


//get single profile
function* profilesingle(data) {
  const profile_single = yield api.postWithPayloadwithToken(
    '/api/account/profile', data
  );
  yield put({ type: 'profilesingle_response', profile_single });
}
function* actionWatcher_single_profile() {
  const single_profile = yield actionChannel('profilesingle');
  while (true) {
    const { data } = yield take(single_profile);
    yield call(profilesingle, data);
  }
}

//update profile
function* updateprofile(data) {
  const profile_update = yield api.postWithPayloadwithToken(
    '/api/account/profile/update',
    data
  );
  yield put({ type: 'updateprofile_response', profile_update });
}
function* actionWatcher_profileupda() {
  const request_profileupdate = yield actionChannel('updateprofile');
  while (true) {
    const { data } = yield take(request_profileupdate);
    yield call(updateprofile, data);
  }
}

//get single compamyconfig
function* companyConfigSingle(data) {
  const companyConfigSingle_res = yield api.postWithPayloadwithToken(
    '/api/account/company-config/get', data
  );
  yield put({ type: 'companyConfigSingle_response', companyConfigSingle_res });
}
function* actionWatcher_single_company_config() {
  const single_company_config = yield actionChannel('companyConfigSingle');
  while (true) {
    const { data } = yield take(single_company_config);
    yield call(companyConfigSingle, data);
  }
}

//update companyconfig
function* updatecompanyconfig(data) {
  const companyconfig_update = yield api.postWithPayloadwithToken(
    '/api/account/company-config/update',
    data
  );
  yield put({ type: 'updatecompanyconfig_response', companyconfig_update });
}
function* actionWatcher_companyconfigupdate() {
  const request_companyconfigupdate = yield actionChannel('updatecompanyconfig');
  while (true) {
    const { data } = yield take(request_companyconfigupdate);
    yield call(updatecompanyconfig, data);
  }
}

//address Dropdown 
//country Search
function* countrysearchlist(data) {
  const country_response_search = yield api.postWithPayloadwithToken(
    '/api/countries/search',
    data
  );
  yield put({ type: 'countrysearch_response', country_response_search });
}
function* actionWatcher_country_search() {
  const request_countrysearchlist = yield actionChannel('country_search');
  while (true) {
    const { data } = yield take(request_countrysearchlist);
    yield call(countrysearchlist, data);
  }
}

//state Search
function* statesearchlist(data) {
  const state_response_search = yield api.postWithPayloadwithToken(
    '/api/states/search',
    data
  );
  yield put({ type: 'statesearch_response', state_response_search });
}
function* actionWatcher_state_search() {
  const request_statesearchlist = yield actionChannel('state_search');
  while (true) {
    const { data } = yield take(request_statesearchlist);
    yield call(statesearchlist, data);
  }
}


//district Search
function* districtsearchlist(data) {
  const district_response_search = yield api.postWithPayloadwithToken(
    '/api/districts/search',
    data
  );
  yield put({ type: 'districtsearch_response', district_response_search });
}
function* actionWatcher_district_search() {
  const request_districtsearchlist = yield actionChannel('district_search');
  while (true) {
    const { data } = yield take(request_districtsearchlist);
    yield call(districtsearchlist, data);
  }
}

//city Search
function* citysearchlist(data) {
  const city_response_search = yield api.postWithPayloadwithToken(
    '/api/cities/search',
    data
  );
  yield put({ type: 'citysearch_response', city_response_search });
}
function* actionWatcher_city_search() {
  const request_citysearchlist = yield actionChannel('city_search');
  while (true) {
    const { data } = yield take(request_citysearchlist);
    yield call(citysearchlist, data);
  }
}

//Route management starts here 
//Route management create 
function* routecreate(data) {
  const route_create_res = yield api.postWithPayloadwithToken(
    '/api/route-plans/create',
    data
  );
  yield put({ type: 'routecreate_response', route_create_res });
}
function* actionWatcher_route_manage_create() {
  const request_routecreate = yield actionChannel('routecreate');
  while (true) {
    const { data } = yield take(request_routecreate);
    yield call(routecreate, data);
  }
}

//route listing
function* routemanagementlist(data) {
  const routemanagement_res = yield api.postWithPayloadwithToken(
    '/api/route-plans/list',
    data
  );
  yield put({ type: 'routemanagementlist_response', routemanagement_res });
}
function* actionWatcher_route_manage_list() {
  const request_routemanagementlist = yield actionChannel('routemanagementlist');
  while (true) {
    const { data } = yield take(request_routemanagementlist);
    yield call(routemanagementlist, data);
  }
}


//route delete 
function* deleteroute(item_id) {
  const route_delete = yield api.postWithPayloadwithToken(
    '/api/route-plans/delete/' + item_id
  );
  yield put({ type: 'delete_route_response', route_delete });
}
function* actionWatcherdeleteroute() {
  const res_delete = yield actionChannel('delete_route');
  while (true) {
    const { item_id } = yield take(res_delete);
    yield call(deleteroute, item_id);
  }
}


//get single route
function* routesingle(data) {
  const route_single = yield api.postWithPayloadwithToken(
    '/api/route-plans/get/' + data
  );
  yield put({ type: 'routesingle_response', route_single });
}
function* actionWatcher_single_route() {
  const single_route = yield actionChannel('routesingle');
  while (true) {
    const { data } = yield take(single_route);
    yield call(routesingle, data);
  }
}

//route management update
function* routeupdate(data, id) {
  const route_update = yield api.postWithPayloadwithToken(
    '/api/route-plans/update/' + id,
    data
  );
  yield put({ type: 'routeupdate_response', route_update });
}
function* actionWatcher_route_manage_update() {
  const request_routeupdate = yield actionChannel('routeupdate');
  while (true) {
    const { data, id } = yield take(request_routeupdate);
    yield call(routeupdate, data, id);
  }
}


//Tour closure 
function* closeTour(data, id) {
  const closeTourRes_data = yield api.postWithPayloadwithToken(
    '/api/route-plans/tour-closure/' + id,
    data
  );
  yield put({ type: 'closeTour_response', closeTourRes_data });
}
function* actionWatcher_tourClose() {
  const request_closeTour = yield actionChannel('closeTour');
  while (true) {
    const { data, id } = yield take(request_closeTour);
    yield call(closeTour, data, id);
  }
}


//outstanding upload
function* outstandexport(data) {
  const outstandingUploadresponse = yield api.postWithPayloadwithTokenandmultipart(
    '/api/customers/outstanding', data);
  yield put({ type: 'outstandingUpload_response', outstandingUploadresponse });
}
function* actionWatcheroutstandexport() {
  const requestoutstand = yield actionChannel('outstandingUpload');
  while (true) {
    const { data } = yield take(requestoutstand);
    yield call(outstandexport, data);
  }
}


//outstanding list
function* outstandlist(data) {
  const outstand_res = yield api.postWithPayloadwithToken(
    '/api/customers/outstanding/list',
    data
  );
  yield put({ type: 'outstandlist_response', outstand_res });
}
function* actionWatcher_outstand_list() {
  const request_outstandlist = yield actionChannel('outstandlist');
  while (true) {
    const { data } = yield take(request_outstandlist);
    yield call(outstandlist, data);
  }
}

//Outstanding user list
function* outstanding_user_list_call(data) {
  const outstanding_user_list_res = yield api.postWithPayloadwithToken(
    '/api/customers/outstanding/user-list',
    data
  );
  yield put({ type: 'outstanding_user_list_response', outstanding_user_list_res });
}
function* actionWatcher_outstanding_user_list() {
  const request_outstanding_user_list = yield actionChannel('outstanding_user_list');
  while (true) {
    const { data } = yield take(request_outstanding_user_list);
    yield call(outstanding_user_list_call, data);
  }
}
//Outstanding user list Ends Here

//dispatch details
//disptach list 
function* dispatchlist(data) {
  const dispatch_res = yield api.postWithPayloadwithToken(
    '/api/dispatch/list', data
  );
  yield put({ type: 'dispatchlist_response', dispatch_res });
}
function* actionWatcher_dispatch_list() {
  const request_dispatchlist = yield actionChannel('dispatchlist');
  while (true) {
    const { data } = yield take(request_dispatchlist);
    yield call(dispatchlist, data);
  }
}

//dispatch export
function* dispatchUpload(data) {
  const dispatchUploadresponse = yield api.postWithPayloadwithTokenandmultipart(
    '/api/dispatch/uploadCsv', data);
  yield put({ type: 'dispatchUpload_response', dispatchUploadresponse });
}
function* actionWatcherdispatchexport() {
  const requestdispatch = yield actionChannel('dispatchUpload');
  while (true) {
    const { data } = yield take(requestdispatch);
    yield call(dispatchUpload, data);
  }
}

//dispatch export
function* dispatchStatus(data) {
  const dispatchStatusresponse = yield api.postWithPayloadwithToken(
    '/api/csvfile-detail/status', data);
  yield put({ type: 'dispatchStatus_response', dispatchStatusresponse });
}
function* actionWatcherdispatchStatus() {
  const requestdispatch = yield actionChannel('dispatchStatus');
  while (true) {
    const { data } = yield take(requestdispatch);
    yield call(dispatchStatus, data);
  }
}


function* dispatch_user_list_call(data) {
  const dispatch_user_list_res = yield api.postWithPayloadwithToken(
    '/api/dispatch/user-list',
    data
  );
  yield put({ type: 'dispatch_user_list_response', dispatch_user_list_res });
}
function* actionWatcher_dispatch_user_list() {
  const request_dispatch_user_list = yield actionChannel('dispatch_user_list');
  while (true) {
    const { data } = yield take(request_dispatch_user_list);
    yield call(dispatch_user_list_call, data);
  }
}


//app version update
function* versionUpdate(data, versionId) {
  const version_update = yield api.postWithPayloadwithToken(
    '/api/version/update/' + versionId,
    data
  );
  yield put({ type: 'versionUpdate_response', version_update });
}
function* actionWatcher_versionUpdate() {
  const request_versionUpdate = yield actionChannel('versionUpdate');
  while (true) {
    const { data, versionId } = yield take(request_versionUpdate);
    yield call(versionUpdate, data, versionId);
  }
}

//Rate Management
//rate List 
function* ratelist(data) {
  const rate_response = yield api.postWithPayloadwithToken(
    '/api/exmillwhlmap/list', data);
  yield put({ type: 'rate_res', rate_response });
}
function* actionWatcherrate() {
  const requestrate = yield actionChannel('ratelist');
  while (true) {
    const { data } = yield take(requestrate);
    yield call(ratelist, data);
  }
}


//Rate Create 
function* ratecreate(data) {
  const rate_create_res = yield api.postWithPayloadwithToken(
    '/api/exmillwhlmap/create', data);
  yield put({ type: 'rate_create_response', rate_create_res });
}
function* actionWatcherratecreate() {
  const requestratecreate = yield actionChannel('ratecreate');
  while (true) {
    const { data } = yield take(requestratecreate);
    yield call(ratecreate, data);
  }
}


//rate Delete
function* deleterate(item_id) {
  const rate_delete = yield api.postWithPayloadwithToken(
    '/api/exmillwhlmap/delete/' + item_id
  );
  yield put({ type: 'delete_rate_response', rate_delete });
}
function* actionWatcherdeleterate() {
  const res_delete = yield actionChannel('delete_rate');
  while (true) {
    const { item_id } = yield take(res_delete);
    yield call(deleterate, item_id);
  }
}

//Rate Single 
function* ratesingle(data) {
  const rate_single = yield api.postWithPayloadwithToken(
    '/api/exmillwhlmap/get/' + data
  );
  yield put({ type: 'ratesingle_response', rate_single });
}
function* actionWatcher_single_rate() {
  const single_rate = yield actionChannel('ratesingle');
  while (true) {
    const { data } = yield take(single_rate);
    yield call(ratesingle, data);
  }
}


//Rate management update
function* rateupdate(data, id) {
  const rate_update = yield api.postWithPayloadwithToken(
    '/api/exmillwhlmap/update/' + id,
    data
  );
  yield put({ type: 'rateupdate_response', rate_update });
}
function* actionWatcher_rate_manage_update() {
  const request_rateupdate = yield actionChannel('rateupdate');
  while (true) {
    const { data, id } = yield take(request_rateupdate);
    yield call(rateupdate, data, id);
  }
}





//Sr ASM Assign management create 
function* srasmmanagementcreate(data) {
  const srasmassigncreate = yield api.postWithPayloadwithToken(
    '/api/assign-srasm/create',
    data
  );
  yield put({ type: 'srAsmUserAssign_response', srasmassigncreate });
}
function* actionWatcher_srasm_manage_create() {
  const srAsmUserAssign_res = yield actionChannel('srAsmUserAssign');
  while (true) {
    const { data } = yield take(srAsmUserAssign_res);
    yield call(srasmmanagementcreate, data);
  }
}

function* srAsmAssign_wholesalerdropdownlist(data) {
  const srAsmAssign_wholesalerdropdownlist_res = yield api.postWithPayloadwithToken(
    '/api/assign-srasm/wholesaler-list',
    data
  );
  yield put({ type: 'srAsmAssign_wholesalerdropdownlist_response', srAsmAssign_wholesalerdropdownlist_res });
}
function* actionWatcher_srAsmAssign_wholesalerdropdownlist() {
  const request_srAsmAssign_wholesalerdropdownlist = yield actionChannel('srAsmAssign_wholesalerdropdownlist');
  while (true) {
    const { data } = yield take(request_srAsmAssign_wholesalerdropdownlist);
    yield call(srAsmAssign_wholesalerdropdownlist, data);
  }
}


//Sr ASM Assign List 
function* SrAsmAssignlist(data) {
  const srasmassignList_response = yield api.postWithPayloadwithToken(
    '/api/assign-srasm/list', data);
  yield put({ type: 'srasmassignList_res', srasmassignList_response });
}
function* actionWatcherSrAsmAssignList() {
  const requestsrAsmassignList = yield actionChannel('srasmassignList');
  while (true) {
    const { data } = yield take(requestsrAsmassignList);
    yield call(SrAsmAssignlist, data);
  }
}



//sr asm Delete
function* deletesrAsmAssign(item_id) {
  const srAsmAssign_delete_response = yield api.postWithPayloadwithToken(
    '/api/assign-srasm/delete/' + item_id
  );
  yield put({ type: 'delete_srAsmAssign_response', srAsmAssign_delete_response });
}
function* actionWatcherdeletesrAsmAssign() {
  const res_srAsmAssigndelete = yield actionChannel('delete_srAsmAssign');
  while (true) {
    const { item_id } = yield take(res_srAsmAssigndelete);
    yield call(deletesrAsmAssign, item_id);
  }
}



//sr asm Single 
function* srasmgetsingle(data) {
  const srAsm_single = yield api.postWithPayloadwithToken(
    '/api/assign-srasm/get/' + data
  );
  yield put({ type: 'SrAsmsingle_response', srAsm_single });
}
function* actionWatcher_single_srAsm() {
  const single_srasm = yield actionChannel('SrAsmsingle');
  while (true) {
    const { data } = yield take(single_srasm);
    yield call(srasmgetsingle, data);
  }
}


//Sr ASM management update
function* srasmupdate(data, id) {
  const srAsmUserAssign_update = yield api.postWithPayloadwithToken(
    '/api/assign-srasm/update/' + id,
    data
  );
  yield put({ type: 'srAsmUserAssignUpdate_response', srAsmUserAssign_update });
}
function* actionWatcher_srASM_update() {
  const request_srAsm_update = yield actionChannel('srAsmUserAssignUpdate');
  while (true) {
    const { data, id } = yield take(request_srAsm_update);
    yield call(srasmupdate, data, id);
  }
}

//Sr ASM management dopdown list

function* srasmassigndrpdownList(data) {
  const srasmassigndrpdownList_res = yield api.postWithPayloadwithToken(
    '/api/assign-srasm/dropdown-list', data);
  yield put({ type: 'srasmassigndrpdownList_response', srasmassigndrpdownList_res });
}
function* actionWatcher_srasmassigndrpdownList() {
  const request_srasmassigndrpdownList = yield actionChannel('srasmassigndrpdownList');
  while (true) {
    const { data } = yield take(request_srasmassigndrpdownList);
    yield call(srasmassigndrpdownList, data);
  }
}



//Pack Order
function* orderConfirm(data, id) {
  const orderConfirm_pack = yield api.postWithPayloadwithToken(
    '/api/orders/push-in-logic/' + id,
    data
  );
  yield put({ type: 'orderConfirm_response', orderConfirm_pack });
}
function* actionWatcher_orderConfirm() {
  const request_odr_confirm = yield actionChannel('orderConfirm');
  while (true) {
    const { data, id } = yield take(request_odr_confirm);
    yield call(orderConfirm, data, id);
  }
}



//Unit Code by Shade
function* unitCodeByShade(data) {
  const unitCodeByShade_recieve = yield api.postWithPayloadwithTokenandmultipart(
    '/api/products/uploadUnitCodeCsv', data);
  yield put({ type: 'unitCodeByShade_response', unitCodeByShade_recieve });
}
function* actionWatcherunitCodeByShade() {
  const requestunitCodeByShade = yield actionChannel('unitCodeByShade');
  while (true) {
    const { data } = yield take(requestunitCodeByShade);
    yield call(unitCodeByShade, data);
  }
}



//Scheme management
//Create
function* schemecreate(data) {
  const scheme_create_res = yield api.postWithPayloadwithToken(
    '/api/schemes/create',
    data
  );
  yield put({ type: 'schemecreate_response', scheme_create_res });
}
function* actionWatcher_scheme_manage_create() {
  const request_schemecreate = yield actionChannel('schemecreate');
  while (true) {
    const { data } = yield take(request_schemecreate);
    yield call(schemecreate, data);
  }
}


//list
function* schemelist(data) {
  const scheme_res = yield api.postWithPayloadwithToken(
    '/api/schemes/list',
    data
  );
  yield put({ type: 'schemelist_response', scheme_res });
}
function* actionWatcher_scheme_list() {
  const request_schemelist = yield actionChannel('schemelist');
  while (true) {
    const { data } = yield take(request_schemelist);
    yield call(schemelist, data);
  }
}


//scheme delete 
function* deletescheme(item_id) {
  const scheme_delete = yield api.postWithPayloadwithToken(
    '/api/schemes/delete/' + item_id
  );
  yield put({ type: 'delete_scheme_response', scheme_delete });
}
function* actionWatcherdeletescheme() {
  const res_delete = yield actionChannel('delete_scheme');
  while (true) {
    const { item_id } = yield take(res_delete);
    yield call(deletescheme, item_id);
  }
}


//get single scheme
function* schemesingle(data) {
  const scheme_single = yield api.postWithPayloadwithToken(
    '/api/schemes/get/' + data
  );
  yield put({ type: 'schemesingle_response', scheme_single });
}
function* actionWatcher_single_scheme() {
  const single_scheme = yield actionChannel('schemesingle');
  while (true) {
    const { data } = yield take(single_scheme);
    yield call(schemesingle, data);
  }
}



//scheme update
function* schemeupdate(data, id) {
  const scheme_update = yield api.postWithPayloadwithToken(
    '/api/schemes/update/' + id,
    data
  );
  yield put({ type: 'schemeupdate_response', scheme_update });
}
function* actionWatcher_scheme_manage_update() {
  const request_schemementupdate = yield actionChannel('schemeupdate');
  while (true) {
    const { data, id } = yield take(request_schemementupdate);
    yield call(schemeupdate, data, id);
  }
}

function* schemesearchlist(data) {
  const schemesearchlist_res = yield api.postWithPayloadwithToken(
    '/api/schemes/search',
    data
  );
  yield put({ type: 'schemesearchlist_response', schemesearchlist_res });
}
function* actionWatcher_schemesearchlist() {
  const request_schemesearchlist = yield actionChannel('schemesearchlist');
  while (true) {
    const { data } = yield take(request_schemesearchlist);
    yield call(schemesearchlist, data);
  }
}



//Transporter 
//Create
function* transportercreate(data) {
  const transporter_create_res = yield api.postWithPayloadwithToken(
    '/api/transporters/create',
    data
  );
  yield put({ type: 'transportercreate_response', transporter_create_res });
}
function* actionWatcher_transporter_manage_create() {
  const request_transportercreate = yield actionChannel('transportercreate');
  while (true) {
    const { data } = yield take(request_transportercreate);
    yield call(transportercreate, data);
  }
}




//list
function* transporterlist(data) {
  const transporter_res = yield api.postWithPayloadwithToken(
    '/api/transporters/list',
    data
  );
  yield put({ type: 'transporterlist_response', transporter_res });
}
function* actionWatcher_transporter_list() {
  const request_transporterlist = yield actionChannel('transporterlist');
  while (true) {
    const { data } = yield take(request_transporterlist);
    yield call(transporterlist, data);
  }
}


//delete 
function* deletetransporter(item_id) {
  const transporter_delete = yield api.postWithPayloadwithToken(
    '/api/transporters/delete/' + item_id
  );
  yield put({ type: 'delete_transporter_response', transporter_delete });
}
function* actionWatcherdeletetransporter() {
  const res_delete = yield actionChannel('delete_transporter');
  while (true) {
    const { item_id } = yield take(res_delete);
    yield call(deletetransporter, item_id);
  }
}


//get single Transporter
function* transportersingle(data) {
  const transporter_single = yield api.postWithPayloadwithToken(
    '/api/transporters/get/' + data
  );
  yield put({ type: 'transportersingle_response', transporter_single });
}
function* actionWatcher_single_transporter() {
  const single_transporter = yield actionChannel('transportersingle');
  while (true) {
    const { data } = yield take(single_transporter);
    yield call(transportersingle, data);
  }
}



//Transporter update
function* transporterupdate(data, id) {
  const transporter_update = yield api.postWithPayloadwithToken(
    '/api/transporters/update/' + id,
    data
  );
  yield put({ type: 'transporterupdate_response', transporter_update });
}
function* actionWatcher_transporter_manage_update() {
  const request_transporterupdate = yield actionChannel('transporterupdate');
  while (true) {
    const { data, id } = yield take(request_transporterupdate);
    yield call(transporterupdate, data, id);
  }
}


//upload Transporter 
function* transporterUpload(file) {
  const transporterUploadresponse = yield api.postWithPayloadwithTokenandmultipart(
    '/api/transporters/import-csv', file);
  yield put({ type: 'transporterUpload_response', transporterUploadresponse });
}
function* actionWatchertransporterUpload() {
  const requesttransporterup = yield actionChannel('transporterUpload');
  while (true) {
    const { file } = yield take(requesttransporterup);
    yield call(transporterUpload, file);
  }
}

function* transporterdropdownlist(data) {
  const transporterdropdownlist_res = yield api.postWithPayloadwithToken(
    '/api/transporters/dropdown-list',
    data
  );
  yield put({ type: 'transporterdropdownlist_response', transporterdropdownlist_res });
}
function* actionWatcher_transporterdropdownlist() {
  const request_transporterdropdownlist = yield actionChannel('transporterdropdownlist');
  while (true) {
    const { data } = yield take(request_transporterdropdownlist);
    yield call(transporterdropdownlist, data);
  }
}

//Retaler Module
//Create
function* retailerCreate(data) {
  const retailer_create_res = yield api.postWithPayloadwithToken(
    '/api/retailers/create',
    data
  );
  yield put({ type: 'retailerCreate_response', retailer_create_res });
}
function* actionWatcher_reailer_manage_create() {
  const request_retailerCreate = yield actionChannel('retailerCreate');
  while (true) {
    const { data } = yield take(request_retailerCreate);
    yield call(retailerCreate, data);
  }
}

//List
function* retailerlist(data) {
  const retailerlist_res = yield api.postWithPayloadwithToken(
    '/api/retailers/list',
    data
  );
  yield put({ type: 'retailerlist_response', retailerlist_res });
}
function* actionWatcher_retailer_manage_list() {
  const request_retailerlist = yield actionChannel('retailerlist');
  while (true) {
    const { data } = yield take(request_retailerlist);
    yield call(retailerlist, data);
  }
}

//retailer delete 
function* deleteretailer(item_id) {
  const retailer_delete = yield api.postWithPayloadwithToken(
    '/api/retailers/delete/' + item_id
  );
  yield put({ type: 'delete_retailer_response', retailer_delete });
}
function* actionWatcherdeleteretailer() {
  const res_delete = yield actionChannel('delete_retailer');
  while (true) {
    const { item_id } = yield take(res_delete);
    yield call(deleteretailer, item_id);
  }
}


//get single retailer
function* retailersingle(data) {
  const retailer_single = yield api.postWithPayloadwithToken(
    '/api/retailers/get/' + data
  );
  yield put({ type: 'retailersingle_response', retailer_single });
}
function* actionWatcher_single_retailer() {
  const single_retailer = yield actionChannel('retailersingle');
  while (true) {
    const { data } = yield take(single_retailer);
    yield call(retailersingle, data);
  }
}

//retailer management update
function* retailermanageupdate(data, id) {
  const retailer_update = yield api.postWithPayloadwithToken(
    '/api/retailers/update/' + id,
    data
  );
  yield put({ type: 'retailermanageupdate_response', retailer_update });
}
function* actionWatcher_retailer_manage_update() {
  const request_retailermanagementupdate = yield actionChannel('retailermanageupdate');
  while (true) {
    const { data, id } = yield take(request_retailermanagementupdate);
    yield call(retailermanageupdate, data, id);
  }
}



//Category Module
//List
function* categorylist(data) {
  const categorylist_res = yield api.postWithPayloadwithToken(
    '/api/categories/list',
    data
  );
  yield put({ type: 'categorylist_response', categorylist_res });
}
function* actionWatcher_category_manage_list() {
  const request_categorylist = yield actionChannel('categorylist');
  while (true) {
    const { data } = yield take(request_categorylist);
    yield call(categorylist, data);
  }
}



//Create
function* categoryCreate(data) {
  const category_create_res = yield api.postWithPayloadwithToken(
    '/api/categories/create',
    data
  );
  yield put({ type: 'categoryCreate_response', category_create_res });
}
function* actionWatcher_category_manage_create() {
  const request_categoryCreate = yield actionChannel('categoryCreate');
  while (true) {
    const { data } = yield take(request_categoryCreate);
    yield call(categoryCreate, data);
  }
}

// category delete 
function* deletecategory(item_id) {
  const category_delete = yield api.postWithPayloadwithToken(
    '/api/categories/delete/' + item_id
  );
  yield put({ type: 'delete_category_response', category_delete });
}
function* actionWatcherdeletecategory() {
  const res_delete = yield actionChannel('delete_category');
  while (true) {
    const { item_id } = yield take(res_delete);
    yield call(deletecategory, item_id);
  }
}


//get single category
function* categorySingle(data) {
  const category_single = yield api.postWithPayloadwithToken(
    '/api/categories/get/' + data
  );
  yield put({ type: 'categorySingle_response', category_single });
}
function* actionWatcher_single_category() {
  const single_category = yield actionChannel('categorySingle');
  while (true) {
    const { data } = yield take(single_category);
    yield call(categorySingle, data);
  }
}

//category management update
function* categoryUpdate(data, id) {
  const category_update = yield api.postWithPayloadwithToken(
    '/api/categories/update/' + id,
    data
  );
  yield put({ type: 'categoryUpdate_response', category_update });
}
function* actionWatcher_category_manage_update() {
  const request_categorymanagementupdate = yield actionChannel('categoryUpdate');
  while (true) {
    const { data, id } = yield take(request_categorymanagementupdate);
    yield call(categoryUpdate, data, id);
  }
}

//category Search
function* categorysearchlist(data) {
  const category_response_search = yield api.postWithPayloadwithToken(
    '/api/categories/search',
    data
  );
  yield put({ type: 'categorysearch_response', category_response_search });
}
function* actionWatcher_category_search() {
  const request_categorysearchlist = yield actionChannel('category_search');
  while (true) {
    const { data } = yield take(request_categorysearchlist);
    yield call(categorysearchlist, data);
  }
}



//Trend
//List
function* trendlist(data) {
  const trendlist_res = yield api.postWithPayloadwithToken(
    '/api/trendings/list',
    data
  );
  yield put({ type: 'trendlist_response', trendlist_res });
}
function* actionWatcher_trend_manage_list() {
  const request_trendlist = yield actionChannel('trendlist');
  while (true) {
    const { data } = yield take(request_trendlist);
    yield call(trendlist, data);
  }
}




//Create
function* trendCreate(data) {
  const trend_create_res = yield api.postWithPayloadwithToken(
    '/api/trendings/create',
    data
  );
  yield put({ type: 'trendCreate_response', trend_create_res });
}
function* actionWatcher_trend_manage_create() {
  const request_trendCreate = yield actionChannel('trendCreate');
  while (true) {
    const { data } = yield take(request_trendCreate);
    yield call(trendCreate, data);
  }
}


// trend delete 
function* deletetrend(item_id) {
  const trend_delete = yield api.postWithPayloadwithToken(
    '/api/trendings/delete/' + item_id
  );
  yield put({ type: 'delete_trend_response', trend_delete });
}
function* actionWatcherdeletetrend() {
  const res_delete = yield actionChannel('delete_trend');
  while (true) {
    const { item_id } = yield take(res_delete);
    yield call(deletetrend, item_id);
  }
}



//get single trend
function* trendSingle(data) {
  const trend_single = yield api.postWithPayloadwithToken(
    '/api/trendings/get/' + data
  );
  yield put({ type: 'trendsingle_response', trend_single });
}
function* actionWatcher_single_trend() {
  const single_trend = yield actionChannel('trendsingle');
  while (true) {
    const { data } = yield take(single_trend);
    yield call(trendSingle, data);
  }
}


//trend update
function* trendUpdate(data, id) {
  const trend_update = yield api.postWithPayloadwithToken(
    '/api/trendings/update/' + id,
    data
  );
  yield put({ type: 'trendUpdate_response', trend_update });
}
function* actionWatcher_trend_manage_update() {
  const request_trendmanagementupdate = yield actionChannel('trendUpdate');
  while (true) {
    const { data, id } = yield take(request_trendmanagementupdate);
    yield call(trendUpdate, data, id);
  }
}




//E-catalogue
//List
function* cataloguelist(data) {
  const cataloguelist_res = yield api.postWithPayloadwithToken(
    '/api/catalogues/list',
    data
  );
  yield put({ type: 'cataloguelist_response', cataloguelist_res });
}
function* actionWatcher_catalogue_manage_list() {
  const request_cataloguelist = yield actionChannel('cataloguelist');
  while (true) {
    const { data } = yield take(request_cataloguelist);
    yield call(cataloguelist, data);
  }
}


//Create
function* catalogueCreate(data) {
  const catalogue_create_res = yield api.postWithPayloadwithToken(
    '/api/catalogues/create',
    data
  );
  yield put({ type: 'catalogueCreate_response', catalogue_create_res });
}
function* actionWatcher_catalogue_manage_create() {
  const request_catalogueCreate = yield actionChannel('catalogueCreate');
  while (true) {
    const { data } = yield take(request_catalogueCreate);
    yield call(catalogueCreate, data);
  }
}

//get single catalogue
function* catalogueSingle(data) {
  const catalogue_single = yield api.postWithPayloadwithToken(
    '/api/catalogues/get/' + data
  );
  yield put({ type: 'cataloguesingle_response', catalogue_single });
}
function* actionWatcher_single_catalogue() {
  const single_catalogue = yield actionChannel('cataloguesingle');
  while (true) {
    const { data } = yield take(single_catalogue);
    yield call(catalogueSingle, data);
  }
}


// catalogue delete 
function* deletecatalogue(item_id) {
  const catalogue_delete = yield api.postWithPayloadwithToken(
    '/api/catalogues/delete/' + item_id
  );
  yield put({ type: 'delete_catalogue_response', catalogue_delete });
}
function* actionWatcherdeletecatalogue() {
  const res_delete = yield actionChannel('delete_catalogue');
  while (true) {
    const { item_id } = yield take(res_delete);
    yield call(deletecatalogue, item_id);
  }
}



//catalogue update
function* catalogueupdate(data, id) {
  const catalogue_update = yield api.postWithPayloadwithToken(
    '/api/catalogues/update/' + id,
    data
  );
  yield put({ type: 'catalogueupdate_response', catalogue_update });
}
function* actionWatcher_catalogue_manage_update() {
  const request_calalogueupdate = yield actionChannel('catalogueupdate');
  while (true) {
    const { data, id } = yield take(request_calalogueupdate);
    yield call(catalogueupdate, data, id);
  }
}



//Mis Graph
function* graphDataReq(data) {
  const graphData = yield api.postWithPayloadwithToken(
    '/api/mis/brand-orders', data
  );
  yield put({ type: 'graphDataReq_response', graphData });
}
function* actionWatcher_graphDataReq() {
  const single_catalogue = yield actionChannel('graphDataReq');
  while (true) {
    const { data } = yield take(single_catalogue);
    yield call(graphDataReq, data);
  }
}

function* graphDataReq2_call(data) {
  const graphDataReq2_res = yield api.postWithPayloadwithToken(
    '/api/mis/confirm-pending-orders', data
  );
  yield put({ type: 'graphDataReq2_response', graphDataReq2_res });
}
function* actionWatcher_graphDataReq2() {
  const graphReq = yield actionChannel('graphDataReq2');
  while (true) {
    const { data } = yield take(graphReq);
    yield call(graphDataReq2_call, data);
  }
}


//Mis Table
function* misReportTableReq(data) {
  const misTableData = yield api.postWithPayloadwithToken(
    '/api/mis/agent-brand-orders', data
  );
  yield put({ type: 'misReportTableReq_response', misTableData });
}
function* actionWatcher_misReportTableReq() {
  const misTable = yield actionChannel('misReportTableReq');
  while (true) {
    const { data } = yield take(misTable);
    yield call(misReportTableReq, data);
  }
}

//Mis Table
function* misReportTableReq2(data) {
  const misTableData2 = yield api.postWithPayloadwithToken(
    '/api/mis/agent-brand-orders2', data
  );
  yield put({ type: 'misReportTableReq2_response', misTableData2 });
}
function* actionWatcher_misReportTableReq2() {
  const misTable2 = yield actionChannel('misReportTableReq2');
  while (true) {
    const { data } = yield take(misTable2);
    yield call(misReportTableReq2, data);
  }
}

//Mis setting Single
function* misSettingSingle(data) {
  const misSingleSetting = yield api.postWithPayloadwithToken(
    '/api/site-preferences/get/mis-config', data
  );
  yield put({ type: 'misSettingSingle_response', misSingleSetting });
}
function* actionWatcher_misSettingSingle() {
  const misTable = yield actionChannel('misSettingSingle');
  while (true) {
    const { data } = yield take(misTable);
    yield call(misSettingSingle, data);
  }
}


//Mis setting update
function* misSettingUpdate(data) {
  const misSetting_update = yield api.postWithPayloadwithToken(
    '/api/site-preferences/update/mis-config', data
  );
  yield put({ type: 'misSettingUpdate_response', misSetting_update });
}
function* actionWatcher_misSettingUpdate() {
  const misTable = yield actionChannel('misSettingUpdate');
  while (true) {
    const { data } = yield take(misTable);
    yield call(misSettingUpdate, data);
  }
}

//Mis Pdf Export
function* misPdf(data) {
  const misPdfExportData = yield api.postWithPayloadwithToken(
    '/api/mis/export', data
  );
  yield put({ type: 'misPdfExport_response', misPdfExportData });
}
function* actionWatcher_misPdf() {
  const misPdfReportDownload = yield actionChannel('misPdfExport');
  while (true) {
    const { data } = yield take(misPdfReportDownload);
    yield call(misPdf, data);
  }
}


//Company 
//company Create
function* companyCreate(data) {
  const companyData = yield api.postWithPayloadwithToken(
    '/api/companies/create',
    data
  );
  yield put({ type: 'companyCreate_response', companyData });
}
function* actionWatcher_company_manage_create() {
  const request_companyCreate = yield actionChannel('companyCreate');
  while (true) {
    const { data } = yield take(request_companyCreate);
    yield call(companyCreate, data);
  }
}



//company single
function* companySingle(data) {
  const singleCompanyData = yield api.postWithPayloadwithToken(
    '/api/companies/get/' + data,
  );
  yield put({ type: 'companySingle_response', singleCompanyData });
}
function* actionWatcher_company_manage_single() {
  const request_companySingle = yield actionChannel('companySingle');
  while (true) {
    const { data } = yield take(request_companySingle);
    yield call(companySingle, data);
  }
}


//company update
function* companyUpdate(data, id) {
  const company_update = yield api.postWithPayloadwithToken(
    '/api/companies/update/' + id,
    data
  );
  yield put({ type: 'companyUpdate_response', company_update });
}
function* actionWatcher_company_manage_update() {
  const request_companyupdate = yield actionChannel('companyUpdate');
  while (true) {
    const { data, id } = yield take(request_companyupdate);
    yield call(companyUpdate, data, id);
  }
}

//generate secret key
function* generateSecretKey(data) {
  const generatesecretkey_res = yield api.postWithPayloadwithToken(
    '/api/companies/create-auth', data
  );
  yield put({ type: 'generatesecretkey_response', generatesecretkey_res });
}
function* actionWatcher_generatesecretkey() {
  const request_generate_secret_key = yield actionChannel('generatesecretkey');
  while (true) {
    const { data } = yield take(request_generate_secret_key);
    yield call(generateSecretKey, data);
  }
}

//Dicount management

//Create
function* discountCreate(data) {
  const discountCreateRes = yield api.postWithPayloadwithToken(
    '/api/discounts/create',
    data
  );
  yield put({ type: 'discountCreate_response', discountCreateRes });
}
function* actionWatcher_discount_create() {
  const request_discountCreate = yield actionChannel('discountCreate');
  while (true) {
    const { data } = yield take(request_discountCreate);
    yield call(discountCreate, data);
  }
}



//List
function* discountList(data) {
  const discountList_res = yield api.postWithPayloadwithToken(
    '/api/discounts/list',
    data
  );
  yield put({ type: 'discountList_response', discountList_res });
}
function* actionWatcher_discountlist() {
  const request_discountList = yield actionChannel('discountList');
  while (true) {
    const { data } = yield take(request_discountList);
    yield call(discountList, data);
  }
}


//delete
function* dicountDelete(id) {
  const discount_delete = yield api.postWithPayloadwithToken(
    '/api/discounts/delete/' + id
  );
  yield put({ type: 'dicountDelete_response', discount_delete });
}
function* actionWatcher_discountDelete() {
  const request_dicountDelete = yield actionChannel('dicountDelete');
  while (true) {
    const { id } = yield take(request_dicountDelete);
    yield call(dicountDelete, id);
  }
}


//single
function* discountSingle(id) {
  const discount_singleRes = yield api.postWithPayloadwithToken(
    '/api/discounts/get/' + id
  );
  yield put({ type: 'discountSingle_response', discount_singleRes });
}
function* actionWatcher_discountSingle() {
  const request_discountSingle = yield actionChannel('discountSingle');
  while (true) {
    const { id } = yield take(request_discountSingle);
    yield call(discountSingle, id);
  }
}


//update
function* discountUpdate(data, id) {
  const discountUpdateRes = yield api.postWithPayloadwithToken(
    '/api/discounts/update/' + id,
    data
  );
  yield put({ type: 'discountUpdate_response', discountUpdateRes });
}
function* actionWatcher_discount_update() {
  const request_discountUpdate = yield actionChannel('discountUpdate');
  while (true) {
    const { data, id } = yield take(request_discountUpdate);
    yield call(discountUpdate, data, id);
  }
}


//agency
//Create
function* agencyCreate(data) {
  const agencyCreateRes = yield api.postWithPayloadwithToken(
    '/api/agencies/create',
    data
  );
  yield put({ type: 'agencyCreate_response', agencyCreateRes });
}
function* actionWatcher_agency_create() {
  const request_agencyCreate = yield actionChannel('agencyCreate');
  while (true) {
    const { data } = yield take(request_agencyCreate);
    yield call(agencyCreate, data);
  }
}

//list
function* agencyList(data) {
  const agencyListRes = yield api.postWithPayloadwithToken(
    '/api/agencies/list',
    data
  );
  yield put({ type: 'agencyList_response', agencyListRes });
}
function* actionWatcher_agency_list() {
  const request_agencyList = yield actionChannel('agencyList');
  while (true) {
    const { data } = yield take(request_agencyList);
    yield call(agencyList, data);
  }
}


//delete
function* agencyDelete(id) {
  const delete_agencyRes = yield api.postWithPayloadwithToken(
    '/api/agencies/delete/' + id
  );
  yield put({ type: 'delete_agency_response', delete_agencyRes });
}
function* actionWatcher_agencyDelete() {
  const request_agencyDelete = yield actionChannel('delete_agency');
  while (true) {
    const { id } = yield take(request_agencyDelete);
    yield call(agencyDelete, id);
  }
}


//get
function* agencySingle(id) {
  const agency_Single = yield api.postWithPayloadwithToken(
    '/api/agencies/get/' + id
  );
  yield put({ type: 'agencySingle_response', agency_Single });
}
function* actionWatcher_agencySingle() {
  const request_agencySingle = yield actionChannel('agencySingle');
  while (true) {
    const { id } = yield take(request_agencySingle);
    yield call(agencySingle, id);
  }
}



//update
function* agencyUpdate(data, id) {
  const agencyUpdateRes = yield api.postWithPayloadwithToken(
    '/api/agencies/update/' + id,
    data
  );
  yield put({ type: 'agencyUpdate_response', agencyUpdateRes });
}
function* actionWatcher_agency_update() {
  const request_agencyUpdate = yield actionChannel('agencyUpdate');
  while (true) {
    const { data, id } = yield take(request_agencyUpdate);
    yield call(agencyUpdate, data, id);
  }
}



//upload agency 
function* agencyUpload(file) {
  const agencyUploadresponse = yield api.postWithPayloadwithTokenandmultipart(
    '/api/agencies/upload', file);
  yield put({ type: 'agencyUpload_response', agencyUploadresponse });
}
function* actionWatcheragencyUpload() {
  const requestproductup = yield actionChannel('agencyUpload');
  while (true) {
    const { file } = yield take(requestproductup);
    yield call(agencyUpload, file);
  }
}
//agency End


//expense Catagory
//create
function* createExpenseCatagory(data) {
  const expenseCatagoryResponseData = yield api.postWithPayloadwithToken(
    '/api/expense-categories/create',
    data
  );
  yield put({ type: 'expenseCatagoryCreate_response', expenseCatagoryResponseData });
}
function* actionWatcher_expenseCatagory_create() {
  const request_createExpenseCatagory = yield actionChannel('expenseCatagoryCreate');
  while (true) {
    const { data } = yield take(request_createExpenseCatagory);
    yield call(createExpenseCatagory, data);
  }
}




//list
function* expenseCateList(data) {
  const expenseCatagoryListData = yield api.postWithPayloadwithToken(
    '/api/expense-categories/list',
    data
  );
  yield put({ type: 'expenseCatagoryList_response', expenseCatagoryListData });
}
function* actionWatcher_Expense_list() {
  const request_ExpenseList = yield actionChannel('expenseCatagoryList');
  while (true) {
    const { data } = yield take(request_ExpenseList);
    yield call(expenseCateList, data);
  }
}



//delete
function* expenseCatagoryDelete(id, data) {
  const delete_ExpenseCatagoryData = yield api.postWithPayloadwithToken(
    '/api/expense-categories/delete/' + id,
    data
  );
  yield put({ type: 'delete_ExpenseCatagory_response', delete_ExpenseCatagoryData });
}
function* actionWatcher_ExpenseCatagoryDelete() {
  const request_agencyDelete = yield actionChannel('delete_ExpenseCatagory');
  while (true) {
    const { id, data} = yield take(request_agencyDelete);
    yield call(expenseCatagoryDelete, id, data);
  }
}


//get
function* expenseCategorySingle(id) {
  const singleExpenseCategoryData = yield api.postWithPayloadwithToken(
    '/api/expense-categories/get/' + id
  );
  yield put({ type: 'singleExpenseCategory_response', singleExpenseCategoryData });
}
function* actionWatcher_expCategorySingle() {
  const request_expenseCategorySingle = yield actionChannel('singleExpenseCategory');
  while (true) {
    const { id } = yield take(request_expenseCategorySingle);
    yield call(expenseCategorySingle, id);
  }
}


//update
function* expenseCategoryUpdate(data, id) {
  const expenseCategoryUpdateRes = yield api.postWithPayloadwithToken(
    '/api/expense-categories/update/' + id,
    data
  );
  yield put({ type: 'updateExpenseCategory_response', expenseCategoryUpdateRes });
}
function* actionWatcher_expense_update() {
  const request_expenseUpdate = yield actionChannel('updateExpenseCategory');
  while (true) {
    const { data, id } = yield take(request_expenseUpdate);
    yield call(expenseCategoryUpdate, data, id);
  }
}


//expesne Sub Category Search
function* expenseCategorySearch(data) {
  const expenseCategorySearchData = yield api.postWithPayloadwithToken(
    '/api/expense-categories/search',
    data
  );
  yield put({ type: 'expenseCategorySearch_response', expenseCategorySearchData });
}
function* actionWatcher_Expense_Search() {
  const request_ExpenseSearch = yield actionChannel('expenseCategorySearch');
  while (true) {
    const { data } = yield take(request_ExpenseSearch);
    yield call(expenseCategorySearch, data);
  }
}


//expesne Approval list
function* expenseApproList(data) {
  const expenseApprovalListData = yield api.postWithPayloadwithToken(
    '/api/expenses/list',
    data
  );
  yield put({ type: 'expenseApprovalList_response', expenseApprovalListData });
}
function* actionWatcher_Expense_Approval_list() {
  const request_ExpenseList = yield actionChannel('expenseApprovalList');
  while (true) {
    const { data } = yield take(request_ExpenseList);
    yield call(expenseApproList, data);
  }
}


//expesne Approval Request
function* expenseApproReq(data, id) {
  const expenseApproval_Res = yield api.postWithPayloadwithToken(
    '/api/expenses/expense-approval/' + id, data
  );
  yield put({ type: 'approvalRequest_response', expenseApproval_Res });
}
function* actionWatcher_Expense_Approval_Request() {
  const request_ExpenseList = yield actionChannel('approvalRequest');
  while (true) {
    const { data, id } = yield take(request_ExpenseList);
    yield call(expenseApproReq, data, id);
  }
}

function* expense_export_call(data) {
  const expense_export_res = yield api.postWithPayloadwithToken(
    '/api/expenses/export', data);
  yield put({ type: 'expense_export_response', expense_export_res });
}
function* actionWatcher_expense_export() {
  const requestproductup = yield actionChannel('expense_export');
  while (true) {
    const { data } = yield take(requestproductup);
    yield call(expense_export_call, data);
  }
}

//Advance list
function* advance_List(data) {
  const advanceListData = yield api.postWithPayloadwithToken(
    '/api/advances/list',
    data
  );
  yield put({ type: 'advanceList_response', advanceListData });
}
function* actionWatcher_advance_list() {
  const request_Advance_List = yield actionChannel('advanceList');
  while (true) {
    const { data } = yield take(request_Advance_List);
    yield call(advance_List, data);
  }
}


//Advance Action
function* advance_Action(data, id) {
  const advanceActionData = yield api.postWithPayloadwithToken(
    '/api/advances/advance-approval/' + id,
    data
  );
  yield put({ type: 'advanceAction_response', advanceActionData });
}
function* actionWatcher_advance_action() {
  const request_Advance_Action = yield actionChannel('advanceAction');
  while (true) {
    const { data, id } = yield take(request_Advance_Action);
    yield call(advance_Action, data, id);
  }
}


//Advance list
function* advanceManyAction(data) {
  const advanceManyActionData = yield api.postWithPayloadwithToken(
    '/api/advances/bulk-approval',
    data
  );
  yield put({ type: 'advanceManyAction_response', advanceManyActionData });
}
function* actionWatcher_advanceManyAction() {
  const request_advanceManyAction = yield actionChannel('advanceManyAction');
  while (true) {
    const { data } = yield take(request_advanceManyAction);
    yield call(advanceManyAction, data);
  }
}


function* advance_export_call(data) {
  const advance_export_res = yield api.postWithPayloadwithToken(
    '/api/advances/export', data);
  yield put({ type: 'advance_export_response', advance_export_res });
}
function* actionWatcher_advance_export() {
  const requestproductup = yield actionChannel('advance_export');
  while (true) {
    const { data } = yield take(requestproductup);
    yield call(advance_export_call, data);
  }
}

//Touring voucher list
function* touringVoucher(data) {
  const touringVoucherListData = yield api.postWithPayloadwithToken(
    '/api/route-plans/touring-voucher',
    data
  );
  yield put({ type: 'touringVoucherList_response', touringVoucherListData });
}
function* actionWatcher_touringVoucher() {
  const request_touringVoucher = yield actionChannel('touringVoucherList');
  while (true) {
    const { data } = yield take(request_touringVoucher);
    yield call(touringVoucher, data);
  }
}


//Touring voucher CSV 
function* touringCSV(data) {
  const touringCSVData = yield api.postWithPayloadwithToken(
    '/api/route-plans/touring-voucher/export',
    data
  );
  yield put({ type: 'touringCSV_response', touringCSVData });
}
function* actionWatcher_touringCSV() {
  const request_touringCSV = yield actionChannel('touringCSV');
  while (true) {
    const { data } = yield take(request_touringCSV);
    yield call(touringCSV, data);
  }
}

// logical logs 
function* logsList(data) {
  const logicalListData = yield api.postWithPayloadwithToken(
    '/api/logical-logs',
    data
  );
  yield put({ type: 'logical_logs_response', logicalListData });
}
function* actionWatcher_logs_list() {
  const log_List = yield actionChannel('logsList');
  while (true) {
    const { data } = yield take(log_List);
    yield call(logsList, data);
  }
}

//commission range starts

//commission list
function* commissionRangeList(data) {
  const commissionRange_res = yield api.postWithPayloadwithToken(
    '/api/commission-range/list',
    data
  );
  yield put({ type: 'commissionRange_response', commissionRange_res });
}
function* actionWatcher_commissionRange_list() {
  const request_commissionRange = yield actionChannel('commissionRange');
  while (true) {
    const { data } = yield take(request_commissionRange);
    yield call(commissionRangeList, data);
  }
}


//commission range create 

function* commissionRangeCreate(data) {
  const commissionRangeCreate_res = yield api.postWithPayloadwithToken(
    '/api/commission-range/create',
    data
  );
  yield put({ type: 'commissionRangeCreate_response', commissionRangeCreate_res });
}
function* actionWatcher_commissionRange_create() {
  const request_commissionRangeCreate = yield actionChannel('commissionRangeCreate');
  while (true) {
    const { data } = yield take(request_commissionRangeCreate);
    yield call(commissionRangeCreate, data);
  }
}

//commission range delete 

function* commissionRangeDelete(item_id) {
  const commissionRangeDelete_res = yield api.postWithPayloadwithToken(
    '/api/commission-range/delete/' + item_id
  );
  yield put({ type: 'commissionRangeDelete_response', commissionRangeDelete_res });
}
function* actionWatcher_commissionRange_Delete() {
  const request_commissionRangeDelete = yield actionChannel('commissionRangeDelete');
  while (true) {
    const { item_id } = yield take(request_commissionRangeDelete);
    yield call(commissionRangeDelete, item_id);
  }
}

//get single commission range

function* commissionRangeSingle(data) {
  const commissionRangeSingle_res = yield api.postWithPayloadwithToken(
    '/api/commission-range/get/' + data
  );
  yield put({ type: 'commissionRangeSingle_response', commissionRangeSingle_res });
}
function* actionWatcher_commissionRange_single() {
  const request_commissionRangeSingle = yield actionChannel('commissionRangeSingle');
  while (true) {
    const { data } = yield take(request_commissionRangeSingle);
    yield call(commissionRangeSingle, data);
  }
}

//commission range update
function* commissionRangeUpdate(data, id) {
  const commissionRangeUpdate_res = yield api.postWithPayloadwithToken(
    '/api/commission-range/update/' + id,
    data
  );
  yield put({ type: 'commissionRangeUpdate_response', commissionRangeUpdate_res });
}
function* actionWatcher_commissionRange_update() {
  const request_commissionRangeUpdate = yield actionChannel('commissionRangeUpdate');
  while (true) {
    const { data, id } = yield take(request_commissionRangeUpdate);
    yield call(commissionRangeUpdate, data, id);
  }
}

//country starts here

function* country_list_call(data) {
  const country_list_res = yield api.postWithPayloadwithToken(
    '/api/countries/list',
    data
  );
  yield put({ type: 'country_list_response', country_list_res });
}
function* actionWatcher_country_list() {
  const country_list_req = yield actionChannel('country_list');
  while (true) {
    const { data } = yield take(country_list_req);
    yield call(country_list_call, data);
  }
}

// country create 

function* country_create_call(data) {
  const country_create_res = yield api.postWithPayloadwithToken(
    '/api/countries/create',
    data
  );
  yield put({ type: 'country_create_response', country_create_res });
}
function* actionWatcher_country_create() {
  const country_create_req = yield actionChannel('country_create');
  while (true) {
    const { data } = yield take(country_create_req);
    yield call(country_create_call, data);
  }
}

//country delete 

function* country_delete_call(item_id) {
  const country_delete_res = yield api.postWithPayloadwithToken(
    '/api/countries/delete/' + item_id
  );
  yield put({ type: 'country_delete_response', country_delete_res });
}
function* actionWatcher_country_delete() {
  const country_delete_req = yield actionChannel('country_delete');
  while (true) {
    const { item_id } = yield take(country_delete_req);
    yield call(country_delete_call, item_id);
  }
}

//get single country

function* country_get_call(data) {
  const country_get_res = yield api.postWithPayloadwithToken(
    '/api/countries/get/' + data
  );
  yield put({ type: 'country_get_response', country_get_res });
}
function* actionWatcher_country_get() {
  const country_get_req = yield actionChannel('country_get');
  while (true) {
    const { data } = yield take(country_get_req);
    yield call(country_get_call, data);
  }
}

//country update
function* country_update_call(data, id) {
  const country_update_res = yield api.postWithPayloadwithToken(
    '/api/countries/update/' + id,
    data
  );
  yield put({ type: 'country_update_response', country_update_res });
}
function* actionWatcher_country_update() {
  const country_update_req = yield actionChannel('country_update');
  while (true) {
    const { data, id } = yield take(country_update_req);
    yield call(country_update_call, data, id);
  }
}

//country ends here

//state starts here

function* state_list_call(data) {
  const state_list_res = yield api.postWithPayloadwithToken(
    '/api/states/list',
    data
  );
  yield put({ type: 'state_list_response', state_list_res });
}
function* actionWatcher_state_list() {
  const state_list_req = yield actionChannel('state_list');
  while (true) {
    const { data } = yield take(state_list_req);
    yield call(state_list_call, data);
  }
}

// state create 

function* state_create_call(data) {
  const state_create_res = yield api.postWithPayloadwithToken(
    '/api/states/create',
    data
  );
  yield put({ type: 'state_create_response', state_create_res });
}
function* actionWatcher_state_create() {
  const state_create_req = yield actionChannel('state_create');
  while (true) {
    const { data } = yield take(state_create_req);
    yield call(state_create_call, data);
  }
}

//state delete 

function* state_delete_call(item_id) {
  const state_delete_res = yield api.postWithPayloadwithToken(
    '/api/states/delete/' + item_id
  );
  yield put({ type: 'state_delete_response', state_delete_res });
}
function* actionWatcher_state_delete() {
  const state_delete_req = yield actionChannel('state_delete');
  while (true) {
    const { item_id } = yield take(state_delete_req);
    yield call(state_delete_call, item_id);
  }
}

//get single state

function* state_get_call(data) {
  const state_get_res = yield api.postWithPayloadwithToken(
    '/api/states/get/' + data
  );
  yield put({ type: 'state_get_response', state_get_res });
}
function* actionWatcher_state_get() {
  const state_get_req = yield actionChannel('state_get');
  while (true) {
    const { data } = yield take(state_get_req);
    yield call(state_get_call, data);
  }
}

//state update
function* state_update_call(data, id) {
  const state_update_res = yield api.postWithPayloadwithToken(
    '/api/states/update/' + id,
    data
  );
  yield put({ type: 'state_update_response', state_update_res });
}
function* actionWatcher_state_update() {
  const state_update_req = yield actionChannel('state_update');
  while (true) {
    const { data, id } = yield take(state_update_req);
    yield call(state_update_call, data, id);
  }
}

//state ends here

//city starts here

function* city_list_call(data) {
  const city_list_res = yield api.postWithPayloadwithToken(
    '/api/cities/list',
    data
  );
  yield put({ type: 'city_list_response', city_list_res });
}
function* actionWatcher_city_list() {
  const city_list_req = yield actionChannel('city_list');
  while (true) {
    const { data } = yield take(city_list_req);
    yield call(city_list_call, data);
  }
}

// city create 

function* city_create_call(data) {
  const city_create_res = yield api.postWithPayloadwithToken(
    '/api/cities/create',
    data
  );
  yield put({ type: 'city_create_response', city_create_res });
}
function* actionWatcher_city_create() {
  const city_create_req = yield actionChannel('city_create');
  while (true) {
    const { data } = yield take(city_create_req);
    yield call(city_create_call, data);
  }
}

//city delete 

function* city_delete_call(item_id) {
  const city_delete_res = yield api.postWithPayloadwithToken(
    '/api/cities/delete/' + item_id
  );
  yield put({ type: 'city_delete_response', city_delete_res });
}
function* actionWatcher_city_delete() {
  const city_delete_req = yield actionChannel('city_delete');
  while (true) {
    const { item_id } = yield take(city_delete_req);
    yield call(city_delete_call, item_id);
  }
}

//get single city

function* city_get_call(data) {
  const city_get_res = yield api.postWithPayloadwithToken(
    '/api/cities/get/' + data
  );
  yield put({ type: 'city_get_response', city_get_res });
}
function* actionWatcher_city_get() {
  const city_get_req = yield actionChannel('city_get');
  while (true) {
    const { data } = yield take(city_get_req);
    yield call(city_get_call, data);
  }
}

//city update
function* city_update_call(data, id) {
  const city_update_res = yield api.postWithPayloadwithToken(
    '/api/cities/update/' + id,
    data
  );
  yield put({ type: 'city_update_response', city_update_res });
}
function* actionWatcher_city_update() {
  const city_update_req = yield actionChannel('city_update');
  while (true) {
    const { data, id } = yield take(city_update_req);
    yield call(city_update_call, data, id);
  }
}

//city ends here

//Payment Management starts here

function* paymentlistFun(data) { //call payment API
  const payment_res = yield api.postWithPayloadwithToken(
    '/api/payment/list',
    data
  );
  yield put({ type: 'paymentlist_response', payment_res });
}

function* actionWatcher_payment_list() { //call payment api fun
  const req_paymentlist = yield actionChannel('paymentlist');
  while (true) {
    const { data } = yield take(req_paymentlist);
    yield call(paymentlistFun, data);
  }
}

function* paymentSingleFun(id) { //call payment API
  const paymentSingle_res = yield api.postWithPayloadwithToken(
    '/api/payment/get/' + id,
    {}
  );
  yield put({ type: 'paymentSingle_response', paymentSingle_res });
}

function* actionWatcher_payment_get() { //call payment api fun
  const reestSingle_payment = yield actionChannel('paymentSingle');
  while (true) {
    const { id } = yield take(reestSingle_payment);
    yield call(paymentSingleFun, id);
  }
}


function* partyNameList(data) { // call partyname API
  const partynamelist_res = yield api.postWithPayloadwithToken(
    '/api/payment/partynames',
    data
  );
  yield put({ type: 'partynamelist_response', partynamelist_res });
}
function* actionWatcher_partyname_list() { //call partyname api fun
  const req_paymentlist = yield actionChannel('partynamelist');
  while (true) {
    const { data } = yield take(req_paymentlist);
    yield call(partyNameList, data);
  }
}


//export stock 
function* paymentExportFun(data) {
  const paymentExp_res = yield api.postWithPayloadwithToken(
    '/api/payment/exportPayment', data);
  yield put({ type: 'paymentExport_response', paymentExp_res });
}
function* actionWatcher_payment_export() {
  const stockExp_Req = yield actionChannel('paymentExport');
  while (true) {
    const { data } = yield take(stockExp_Req);
    yield call(paymentExportFun, data);
  }
}

//payment delete 
function* deletePaymentCall(item_id) {
  const payment_delete_res = yield api.postWithPayloadwithToken(
    '/api/payment/delete/' + item_id
  );
  yield put({ type: 'delete_payment_response', payment_delete_res });
}
function* actionWatcher_delete_Payment() {
  const req_payment_delete = yield actionChannel('delete_payment');
  while (true) {
    const { item_id } = yield take(req_payment_delete);
    yield call(deletePaymentCall, item_id);
  }
}

//payment status 

function* statusCall(item_id, status) {
  const payment_status_res = yield api.postWithPayloadwithToken(
    `/api/payment/${status}/${item_id}`
  );
  yield put({ type: 'status_payment_response', payment_status_res });
}
function* actionWatcher_status_Payment() {
  const req_payment_status = yield actionChannel('status_payment');
  while (true) {
    const { item_id, status } = yield take(req_payment_status);
    yield call(statusCall, item_id, status);
  }
}


//feedback Management starts here

function* feedbacklistFun(data) { //call feedback API
  const feedback_res = yield api.postWithPayloadwithToken(
    '/api/feedback/list',
    data
  );
  yield put({ type: 'feedbacklist_response', feedback_res });
}

function* actionWatcher_feedback_list() { //call feedback api fun
  const req_feedbacklist = yield actionChannel('feedbacklist');
  while (true) {
    const { data } = yield take(req_feedbacklist);
    yield call(feedbacklistFun, data);
  }
}

function* feedbackSingleFun(id) { //call feedback API
  const feedbackSingle_res = yield api.postWithPayloadwithToken(
    '/api/feedback/get/' + id,
    {}
  );
  yield put({ type: 'feedbackSingle_response', feedbackSingle_res });
}

function* actionWatcher_feedback_get() { //call feedback api fun
  const reestSingle_feedback = yield actionChannel('feedbackSingle');
  while (true) {
    const { id } = yield take(reestSingle_feedback);
    yield call(feedbackSingleFun, id);
  }
}

//export stock 
function* feedbackExportFun(data) {
  const feedbackExp_res = yield api.postWithPayloadwithToken(
    '/api/feedback/export', data);
  yield put({ type: 'feedbackExport_response', feedbackExp_res });
}
function* actionWatcher_feedback_export() {
  const stockExp_Req = yield actionChannel('feedbackExport');
  while (true) {
    const { data } = yield take(stockExp_Req);
    yield call(feedbackExportFun, data);
  }
}

//payment delete 
function* deletefeedbackCall(item_id) {
  const feedback_delete_res = yield api.postWithPayloadwithToken(
    '/api/feedback/delete/' + item_id
  );
  yield put({ type: 'delete_feedback_response', feedback_delete_res });
}
function* actionWatcher_delete_feedback() {
  const req_feedback_delete = yield actionChannel('delete_feedback');
  while (true) {
    const { item_id } = yield take(req_feedback_delete);
    yield call(deletefeedbackCall, item_id);
  }
}

// get options version
function* versionGetCall(data) { //call version API
  const versionGet_res = yield api.postWithPayloadwithToken(
    '/api/options/get/currentBuild',
    {}
  );
  yield put({ type: 'versionGet_response', versionGet_res });
}
function* actionWatcher_version_get() { //call version api fun
  const versionGetReq = yield actionChannel('versionGet');
  while (true) {
    const { data } = yield take(versionGetReq);
    yield call(versionGetCall, data);
  }
}

// update options version
function* versionUpdateCall(data) { //call version API
  const versionUpdateCall_res = yield api.postWithPayloadwithToken(
    '/api/options/update/currentBuild',
    data
  );
  yield put({ type: 'versionUpdateCall_response', versionUpdateCall_res });
}
function* actionWatcher_version_update() { //call version api fun
  const versionUpdateCallReq = yield actionChannel('versionUpdateCall');
  while (true) {
    const { data } = yield take(versionUpdateCallReq);
    yield call(versionUpdateCall, data);
  }
}


//Order Template starts here

function* orderTemplateList(data) {
  const orderTemplate_list_res = yield api.postWithPayloadwithToken(
    '/api/orders-template/list',
    data
  );
  yield put({ type: 'orderTemplate_list_response', orderTemplate_list_res });
}
function* actionWatcher_orderTemplate_list() {
  const req_orderTemplate_list = yield actionChannel('orderTemplate_list');
  while (true) {
    const { data } = yield take(req_orderTemplate_list);
    yield call(orderTemplateList, data);
  }
}

function* orderTemplateRefresh(data) {
  const orderTemplate_refresh_res = yield api.postWithPayloadwithToken(
    '/api/orders-template/refresh',
    data
  );
  yield put({ type: 'orderTemplate_refresh_response', orderTemplate_refresh_res });
}
function* actionWatcher_orderTemplate_refresh() {
  const req_orderTemplate_refresh = yield actionChannel('orderTemplate_refresh');
  while (true) {
    const { data } = yield take(req_orderTemplate_refresh);
    yield call(orderTemplateRefresh, data);
  }
}


// order template create 

function* orderTemplateCreate(data) {
  const orderTemplate_create_res = yield api.postWithPayloadwithToken(
    '/api/orders-template/create',
    data
  );
  yield put({ type: 'orderTemplate_create_response', orderTemplate_create_res });
}
function* actionWatcher_orderTemplate_create() {
  const req_orderTemplate_create = yield actionChannel('orderTemplate_create');
  while (true) {
    const { data } = yield take(req_orderTemplate_create);
    yield call(orderTemplateCreate, data);
  }
}

// order template delete

function* orderTemplateDelete(item_id) {
  const orderTemplate_delete_res = yield api.postWithPayloadwithToken(
    '/api/orders-template/delete/' + item_id
  );
  yield put({ type: 'orderTemplate_delete_response', orderTemplate_delete_res });
}
function* actionWatcher_orderTemplate_delete() {
  const req_orderTemplate_delete = yield actionChannel('orderTemplate_delete');
  while (true) {
    const { item_id } = yield take(req_orderTemplate_delete);
    yield call(orderTemplateDelete, item_id);
  }
}

// order template get

function* orderTemplateGet(data) {
  const orderTemplate_get_res = yield api.postWithPayloadwithToken(
    '/api/orders-template/get/' + data
  );
  yield put({ type: 'orderTemplate_get_response', orderTemplate_get_res });
}
function* actionWatcher_orderTemplate_get() {
  const req_orderTemplate_get = yield actionChannel('orderTemplate_get');
  while (true) {
    const { data } = yield take(req_orderTemplate_get);
    yield call(orderTemplateGet, data);
  }
}

//  order template update
function* orderTemplateUpdate(data, id) {
  const orderTemplate_update_res = yield api.postWithPayloadwithToken(
    '/api/orders-template/update/' + id,
    data
  );
  yield put({ type: 'orderTemplate_update_response', orderTemplate_update_res });
}
function* actionWatcher_orderTemplate_update() {
  const req_orderTemplate_update = yield actionChannel('orderTemplate_update');
  while (true) {
    const { data, id } = yield take(req_orderTemplate_update);
    yield call(orderTemplateUpdate, data, id);
  }
}


//upload stock 
function* orderTemplate_upload_call(file) {
  const orderTemplate_upload_res = yield api.postWithPayloadwithTokenandmultipart(
    '/api/orders-template/upload', file);
  yield put({ type: 'orderTemplate_upload_response', orderTemplate_upload_res });
}
function* actionWatcher_orderTemplate_upload() {
  const orderTemplate_upload_req = yield actionChannel('orderTemplate_upload');
  while (true) {
    const { file } = yield take(orderTemplate_upload_req);
    yield call(orderTemplate_upload_call, file);
  }
}

//export stock 
function* orderTemplate_export_call(data) {
  const orderTemplate_export_res = yield api.postWithPayloadwithToken(
    '/api/orders-template/export', data);
  yield put({ type: 'orderTemplate_export_response', orderTemplate_export_res });
}
function* actionWatchers_orderTemplate_export() {
  const orderTemplate_export_req = yield actionChannel('orderTemplate_export');
  while (true) {
    const { data } = yield take(orderTemplate_export_req);
    yield call(orderTemplate_export_call, data);
  }
}

//Order Template starts here

function* orderTemplateDropDownList(data) {
  const orderTemplateDropDownList_res = yield api.postWithPayloadwithToken(
    '/api/orders-template/dropdown-list',
    data
  );
  yield put({ type: 'orderTemplateDropDownList_response', orderTemplateDropDownList_res });
}
function* actionWatcher_orderTemplateDropDownList() {
  const req_orderTemplate_list = yield actionChannel('orderTemplateDropDownList');
  while (true) {
    const { data } = yield take(req_orderTemplate_list);
    yield call(orderTemplateDropDownList, data);
  }
}

// order template ends here

export default function* rootSaga() {
  yield all([
    actionWatcher_orderTemplate_upload(),
    actionWatchers_orderTemplate_export(),
    actionWatcher_orderTemplateDropDownList(),
    actionWatcher_orderTemplate_list(),
    actionWatcher_orderTemplate_refresh(),
    actionWatcher_orderTemplate_create(),
    actionWatcher_orderTemplate_delete(),
    actionWatcher_orderTemplate_get(),
    actionWatcher_orderTemplate_update(),
    actionWatcher_status_Payment(),
    actionWatcher_version_update(),
    actionWatcher_version_get(),
    actionWatcher_delete_Payment(),
    actionWatcher_payment_export(),
    actionWatcher_partyname_list(),
    actionWatcher_payment_get(),
    actionWatcher_payment_list(),
    actionWatcher_commissionRange_update(),
    actionWatcher_commissionRange_single(),
    actionWatcher_commissionRange_list(),
    actionWatcher_commissionRange_create(),
    actionWatcher_commissionRange_Delete(),
    actionWatcher(),
    actionWatcher_customnotification(),

    actionWatcher_admin_user_list(),
    actionWatcher_admin_user_create(),
    actionWatcher_admin_user_delete(),
    actionWatcher_single_adminuser(),
    actionWatcher_adminuser_update(),

    actionWatcher_roles_list(),
    actionWatcher_roles_create(),
    actionWatcher_roles_delete(),
    actionWatcher_single_roles(),
    actionWatcher_roles_update(),
    actionWatcher_role_name_list(),
    actionWatcher_role_active_update(),

    actionWatcher_country_update(),
    actionWatcher_country_get(),
    actionWatcher_country_delete(),
    actionWatcher_country_create(),
    actionWatcher_country_list(),

    actionWatcher_state_update(),
    actionWatcher_state_get(),
    actionWatcher_state_delete(),
    actionWatcher_state_create(),
    actionWatcher_state_list(),

    actionWatcher_city_update(),
    actionWatcher_city_get(),
    actionWatcher_city_delete(),
    actionWatcher_city_create(),
    actionWatcher_city_list(),

    actionWatcher_otp_req(),
    actionWatcher_logout(),
    actionWatcher_val_otp_req(),
    actionWatcher_user_manage_list(),
    actionWatcher_inactive_customers_manage_list(),
    actionWatcher_inactiveCustomers_Export(),
    actionWatcher_inactive_user_manage_list(),
    actionWatcher_inactiveUsers_Export(),
    actionWatcher_zeroStock_list(),
    actionWatcher_zeroStock_Export(),
    actionWatcher_user_manage_create(),
    actionWatcher_company_list(),
    actionWatcher_single_user(),
    actionWatcher_user_manage_update(),
    actionWatcherdeleteuser(),
    actionWatcher_userExport(),
    actionWatcher_zone_manage_list(),
    actionWatcher_single_uom(),
    actionWatcher_uom_manage_update(),
    actionWatcher_uom_manage_list(),
    actionWatcher_uom_search(),
    actionWatcher_uom_product_search(),
    actionWatcherdeleteuom(),
    actionWatcher_uom_manage_create(),
    actionWatcher_zone_manage_create(),
    actionWatcherdeletezone(),
    actionWatcher_single_zone(),
    actionWatcher_zone_manage_update(),
    actionWatcher_region_manage_list(),
    actionWatcher_region_manage_create(),
    actionWatcherdeleteregion(),
    actionWatcher_single_region(),
    actionWatcher_region_manage_update(),
    actionWatcher_territory_manage_list(),
    actionWatcher_territory_manage_create(),
    actionWatcherdeleteterritory(),
    actionWatcher_single_territory(),
    actionWatcher_territory_manage_update(),
    actionWatcher_beats_manage_list(),
    actionWatcher_beats_manage_create(),
    actionWatcherdeletebeats(),
    actionWatcher_single_beats(),
    actionWatcher_beats_manage_update(),
    actionWatcher_brand_manage_list(),
    actionWatcher_brand_manage_create(),
    actionWatcherdeletebrand(),
    actionWatcher_single_brand(),
    actionWatcher_brand_manage_update(),
    actionWatcher_zone_search(),
    actionWatcher_region_search(),
    actionWatcher_customer_manage_list(),
    actionWatcher_customer_manage_create(),
    actionWatcherdeletecustomer(),
    actionWatcher_single_customer(),
    actionWatcher_customer_manage_update(),
    actionWatcher_product_manage_list(),
    actionWatcher_product_manage_create(),
    actionWatcherdeleteproduct(),
    actionWatcher_single_product(),
    actionWatcher_product_manage_update(),
    actionWatcher_brand_search(),
    actionWatcherproductUpload(),
    actionWatcherstockUpload(),
    actionWatcher_agent_search(),
    actionWatcher_territory_search(),
    actionWatcher_beat_search(),
    actionWatchercustomerUpload(),
    actionWatcher_order_manage_list(),
    actionWatchercustomerexport(),
    actionWatcherproductexport(),
    actionWatcher_productdropdownlist(),
    actionWatcherstockexport(),
    actionWatcherorderexport(),
    actionWatchermultipleorderexport(),
    actionWatcherprofileget(),
    actionWatcher_usersdashboard(),
    actionWatcher_product_top_10(),
    actionWatcher_product_least_10(),
    actionWatcher_customers_top_10(),
    actionWatcher_customers_least_10(),
    actionWatcher_orders_least_10(),
    actionWatcher_orders_top_10(),
    actionWatcher_stock_manage_list(),
    actionWatcher_stocksingle(),
    actionWatcher_stock_manage_update(),
    actionWatcher_changepassword(),
    actionWatcher_single_profile(),
    actionWatcher_profileupda(),
    actionWatcher_single_company_config(),
    actionWatcher_companyconfigupdate(),
    actionWatcher_country_search(),
    actionWatcher_state_search(),
    actionWatcher_district_search(),
    actionWatcher_city_search(),
    actionWatcher_customer_search(),
    actionWatcher_customerdropdownlist(),
    actionWatcher_route_manage_create(),
    actionWatcher_route_manage_list(),
    actionWatcherdeleteroute(),
    actionWatcher_single_route(),
    actionWatcher_route_manage_update(),
    actionWatcheroutstandexport(),
    actionWatcher_outstand_list(),
    actionWatcher_user_active_update(),
    actionWatcher_dispatch_list(),
    actionWatcherdispatchexport(),
    actionWatcherdispatchStatus(),
    actionWatcher_versionUpdate(),
    actionWatcher_indirectcustomer_manage_list(),
    actionWatcher_wholesalerSearch(),
    actionWatcher_indirectcustomer_manage_create(),
    actionWatcherdeleteindirectcustomer(),
    actionWatcher_single_indirectcustomer(),
    actionWatcher_indirect_customer_manage_update(),
    actionWatcherrate(),
    actionWatcherratecreate(),
    actionWatcherdeleterate(),
    actionWatcher_single_rate(),
    actionWatcher_rate_manage_update(),
    actionWatcherindirectcustomerUpload(),
    actionWatcherindirectcustomerexport(),
    actionWatcher_indirectdropdownlist(),
    actionWatcher_user_search(),
    actionWatcher_srasm_manage_create(),
    actionWatcher_srAsmAssign_wholesalerdropdownlist(),
    actionWatcherSrAsmAssignList(),
    actionWatcherdeletesrAsmAssign(),
    actionWatcher_single_srAsm(),
    actionWatcher_srASM_update(),
    actionWatcher_srasmassigndrpdownList(),
    actionWatcher_orderConfirm(),
    actionWatcher_order_no_list(),
    actionWatcherunitCodeByShade(),
    actionWatcher_scheme_manage_create(),
    actionWatcher_scheme_list(),
    actionWatcherdeletescheme(),
    actionWatcher_single_scheme(),
    actionWatcher_scheme_manage_update(),
    actionWatcher_schemesearchlist(),
    actionWatcher_transporter_manage_create(),
    actionWatcher_transporter_list(),
    actionWatcherdeletetransporter(),
    actionWatcher_single_transporter(),
    actionWatcher_transporter_manage_update(),
    actionWatchertransporterUpload(),
    actionWatcher_transporterdropdownlist(),
    actionWatcher_reailer_manage_create(),
    actionWatcher_retailer_manage_list(),
    actionWatcherdeleteretailer(),
    actionWatcher_single_retailer(),
    actionWatcher_retailer_manage_update(),
    actionWatcher_category_manage_list(),
    actionWatcher_category_manage_create(),
    actionWatcher_single_category(),
    actionWatcherdeletecategory(),
    actionWatcher_category_manage_update(),
    actionWatcher_trend_manage_create(),
    actionWatcher_trend_manage_list(),
    actionWatcher_single_trend(),
    actionWatcher_trend_manage_update(),
    actionWatcherdeletetrend(),
    actionWatcher_catalogue_manage_list(),
    actionWatcher_category_search(),
    actionWatcher_catalogue_manage_create(),
    actionWatcher_single_catalogue(),
    actionWatcher_catalogue_manage_update(),
    actionWatcherdeletecatalogue(),
    actionWatcher_graphDataReq(),
    actionWatcher_misReportTableReq(),
    actionWatcher_misReportTableReq2(),
    actionWatcher_company_manage_create(),
    actionWatcher_company_manage_single(),
    actionWatcher_company_manage_update(),
    actionWatcher_misSettingSingle(),
    actionWatcher_misSettingUpdate(),
    actionWatcher_discount_create(),
    actionWatcher_discountlist(),
    actionWatcher_discountDelete(),
    actionWatcher_discountSingle(),
    actionWatcher_discount_update(),
    actionWatcher_agency_create(),
    actionWatcher_agency_list(),
    actionWatcher_agencySingle(),
    actionWatcher_agency_update(),
    actionWatcher_agencyDelete(),
    actionWatcheragencyUpload(),
    actionWatcher_routeOrder(),
    actionWatcher_routeOrder2(),
    actionWatcher_orderOutstandingDays(),
    actionWatcher_misPdf(),
    actionWatcher_expenseCatagory_create(),
    actionWatcher_Expense_list(),
    actionWatcher_ExpenseCatagoryDelete(),
    actionWatcher_expCategorySingle(),
    actionWatcher_expense_update(),
    actionWatcher_Expense_Search(),
    actionWatcher_Expense_Approval_list(),
    actionWatcher_Expense_Approval_Request(),
    actionWatcher_advance_list(),
    actionWatcher_advance_action(),
    actionWatcher_advanceManyAction(),
    actionWatcher_tourClose(),
    actionWatcher_userHierarchy_create(),
    actionWatcher_userHierarchyList(),
    actionWatcher_single_userHierarchy(),
    actionWatcher_delete_userHierarchy(),
    actionWatcher_user_hierarchy_update(),
    actionWatcher_touringVoucher(),
    actionWatcher_touringCSV(),
    actionWatcher_single_order(),
    actionWatcher_email_config(),
    actionWatcher_order_manage_update(),
    actionWatcher_order_manage_reject(),
    actionWatcher_OutstandingDays(),
    actionWatcher_orderProduct(),
    actionWatcher_dispatch_user_list(),
    // actionWatcher_delete_order(),
    actionWatcherorderDelete(),
    actionWatcher_order_pdf(),
    actionWatcher_logs_list(),
    actionWatcher_order_create(),
    actionWatcher_generatesecretkey(),
    actionWatcher_single_commission(),
    actionWatchercommissionDelete(),
    actionWatcher_commission_list(),
    actionWatcher_orderFailed_list(),
    actionWatcher_customers_visit(),
    actionWatcher_customers_visit_export(),
    actionWatcher_expense_export(),
    actionWatcher_advance_export(),
    actionWatcher_stockHistory_get(),
    actionWatcher_stockHistory_list(),
    actionWatcher_graphDataReq2(),
    actionWatcher_outstanding_user_list(),
    actionWatcher_feedback_list(),
    actionWatcher_feedback_get(),
    actionWatcher_feedback_export(),
    actionWatcher_delete_feedback()
  ]);
}
