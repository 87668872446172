import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { srasmassignList, delete_srAsmAssign } from '../../actions';
import { routehelp } from '../../Routehelper';
import Moment from 'moment';
import { errorRender, isPermission, loaderUpdate, successRender } from '../../helpers/functions';
import { ITEM_PER_PAGES, CURRENT_PAGE, SORT_BY, SORT } from '../../helpers/constants';
require('react-datetime');
var dateFormat = require('dateformat');


const LinkWithItag = lazy(() => import('../../components/linkwithItag'));
const Userdropdown = lazy(() => import('../userManagement/userDropdwon'));
const Pagination = lazy(() => import('../../components/pagination/index'));
const Entries = lazy(() => import('../../components/entries/entires'));
const Loader = lazy(() => import('../../components/loader'));
const LinksWithI = lazy(() => import('../../components/linkwithItag'));
const ButtonWithItag = lazy(() => import('../../components/buttonwithTagi'));
const SrAsmDropDown = lazy(() => import('../SrASM-UsersAssignment/srAsmDropdwon'));

let itemsPerPage = ITEM_PER_PAGES;
let currentPage = CURRENT_PAGE;
let sortBy = SORT_BY;
let sort = SORT;
class SrAsmUserAssignment extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isFilter: false,
            srsam: "",
            user: null,
            deleteMessage: "Sr-Asm Assigned Users has been Deleted Successfully"
        }
    }


    componentDidMount() {
        this.loadResult();
    }

    componentWillUnmount() {
        currentPage = 1
        itemsPerPage = 50
        this.setState(this.state)
    }
    loadResult = () => {
        if (this.state.isFilter === true) {
            currentPage = 1;
        }
        let data = {}
        data.filter = { sasm: this.state.srsam && this.state.srsam !== '' ? this.state.srsam.value : undefined }
        if (this.state.user !== null) {
            data.filter['user'] = {
                _id: this.state.user.value,
                type: this.state.user.type
            };
        };
        data.sortBy = sortBy === "" ? "createdAt" : sortBy;
        data.sort = sort === "" ? "desc" : sort;
        data.skip = (currentPage - 1) * itemsPerPage;
        data.limit = itemsPerPage;
        this.props.srasmassignList(data);
        this.setState({ isLoading: true, isFilter: false })
    }

    openandclosefilter = () => this.setState({ isFilter: !this.state.isFilter });

    resetfilter = () => {
        let data = {}
        data.sortBy = sortBy === "" ? "createdAt" : sortBy;
        data.sort = sort === "" ? "desc" : sort;
        data.skip = (currentPage - 1) * itemsPerPage;
        data.limit = itemsPerPage;
        this.props.srasmassignList(data);
        this.setState({ isLoading: true, isFilter: false, srsam: null, user: null })
    }



    sortBy = (key) => {
        sortBy = key;
        if (sort === 'desc') {
            sort = 'asc';
        } else {
            sort = 'desc';
        }
        this.loadResult();
    }

    handleNext = () => {
        currentPage = currentPage + 1;
        this.loadResult();
    }

    handlePrevious = () => {
        currentPage = currentPage - 1;
        this.loadResult();
    }

    handlePage = (page) => {
        currentPage = page;
        this.loadResult();
    }

    handleChangeiItemsPerPage = (items) => {
        currentPage = 1;
        itemsPerPage = items;
        this.loadResult();
    }

    ondropdownsrasm = (e, t) => this.setState({ [e]: t });

    ondropdownuser = (e, t) => this.setState({ [e]: t, userType: t.type });

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
        // this.setState({ [e.target.name]: e.target.value })
    };

    deleteRow = (e, item_id) => {
        this.props.delete_srAsmAssign(item_id);
        e.preventDefault();
    }


    componentWillReceiveProps(props) {
        if (props.ordermanagement_res && props.ordermanagement_res.list && props.ordermanagement_res.count !== 0 && props.ordermanagement_res.list[0].agent !== undefined) {
            this.setState({
                agent:
                    props.ordermanagement_res !== undefined && props.ordermanagement_res.list
                        ? props.ordermanagement_res.list[0].agent.name
                        : '',
                date:
                    props.ordermanagement_res !== undefined && props.ordermanagement_res.list
                        ? Moment(props.ordermanagement_res.list[0].createdAt).format('DD-MM-YYYY')
                        : '',
            });
        } else {
            this.setState({ agent: 'Not Found', date: 'Not Found' });
        }
        return true;
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            if (this.state.isLoading) {
                this.setState({ isLoading: loaderUpdate(nextProps, "srasmassignList_response") });
            }
            successRender(nextProps, "srAsmAssign_delete_response", this.loadResult, this.state.deleteMessage)
            errorRender(nextProps, "ordermanagement_res");
            errorRender(nextProps, "srAsmAssign_delete_response");
        }
        return true;
    }

    render() {
        let { srasmassignList_response } = this.props;
        const totResult = srasmassignList_response !== undefined ? srasmassignList_response.count : 0;
        return (

            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className=" col-sm-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">
                                        Sr. ASM Users Assignment
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div id="bs4-table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4  p-0">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                {totResult === undefined ? "" : <Entries
                                                    itemsPerPage={itemsPerPage}
                                                    handleChangeiItemsPerPage={
                                                        this.handleChangeiItemsPerPage
                                                    }
                                                />}
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="add-btn-icon mb-4">
                                                    <div className="input-group mr-sm-2 search-group">
                                                    </div>
                                                    {isPermission("srASM", "create") ? 
                                                        <LinkWithItag to={routehelp.SrAsmAssignmentAdd} className="btn float-left  btn-primary mr-2" role="button" classNameI="fa fa-plus" title="Add Sr. ASM" />
                                                    : null}
                                                    <ButtonWithItag classNameI="ti-filter" onclick={this.openandclosefilter} type="button" className="btn btn-primary navbar-toggler right-sidebar-toggler" data-toggle="tooltip" data-placement="top" title="Filter" data-original-title="Click for Filter option" />
                                                </div>
                                            </div>
                                        </div>
                                        {totResult === undefined ? "No Record Found" :
                                            this.state.isLoading ? <Loader /> : srasmassignList_response !== undefined && srasmassignList_response.count === 0 ? "No Data Found" : srasmassignList_response && srasmassignList_response.count > 0 ? <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="table-responsive">
                                                        <table id="bs4-table" className="table table-bordered dataTable" cellSpacing="0">
                                                            <thead>
                                                                <tr role="row">
                                                                    <th onClick={() => this.sortBy('srAsm')} className={sort === ''
                                                                        ? 'sorting'
                                                                        : sort === 'asc'
                                                                            ? 'sorting_asc'
                                                                            : 'sorting_desc'}>Sr. ASM </th>
                                                                    <th >Users</th>

                                                                    <th onClick={() => this.sortBy('createdAt')} className={sort === ''
                                                                        ? 'sorting'
                                                                        : sort === 'asc'
                                                                            ? 'sorting_asc'
                                                                            : 'sorting_desc'}>Created At</th>
                                                                    <th >Actions</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {srasmassignList_response !== undefined && srasmassignList_response.list && srasmassignList_response.list.map((item, index) =>
                                                                    <tr key={index} role="row" className="odd notification-list">
                                                                        <td>{item.srAsm.name}</td>
                                                                        <td  >
                                                                            {item.agent.map((items, inx) => {
                                                                                return <p className="mb-0" key={inx}>Agent : {items.name}<br /></p>
                                                                            })}
                                                                            {item.asm.map((items, inx) => {
                                                                                return <p className="mb-0" key={inx}>ASM : {items.name}<br /></p>
                                                                            })}
                                                                            {item.asm2.map((items, inx) => {
                                                                                return <p className="mb-0" key={inx}>ASM 2 : {items.name}<br /></p>
                                                                            })}
                                                                            {item.customer.map((items, inx) => {
                                                                                return <p className="mb-0" key={inx}>Wholesaler : {items.name}<br /></p>
                                                                            })}
                                                                        </td>
                                                                        <td>{dateFormat(new Date(item.createdAt), 'mmm d, yyyy')}</td>
                                                                        <td className="text-center">
                                                                            {isPermission("srASM", "update") ? 
                                                                                <LinksWithI to={routehelp.SrAsmAssignmentUpdate + item._id} item_id={item._id} type="button" classNameI="ti-pencil" className="btn btn-link text-pramiry action-btn mr-2 p-0" />
                                                                            : null}
                                                                            {isPermission("srASM", "delete") ? 
                                                                                <ButtonWithItag onclick={ev => { if (window.confirm('Are you sure you wish to delete this item?')) this.deleteRow(ev, item._id); }} type="button" classNameI="ti-trash" className="btn btn-link text-danger action-btn p-0" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete" />
                                                                            : null}
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div> : "Something Went Wrong"}
                                        {totResult !== undefined ? srasmassignList_response !== undefined && srasmassignList_response.count === 0 ? "" :
                                            <Pagination
                                                handleChangeiItemsPerPage={
                                                    this.handleChangeiItemsPerPage
                                                }
                                                handlePage={this.handlePage}
                                                handleNext={this.handleNext}
                                                handlePrevious={this.handlePrevious}
                                                currentPage={currentPage}
                                                itemsPerPage={itemsPerPage}
                                                totResult={totResult}
                                                key={currentPage}
                                            />
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={!this.state.isFilter ? "right-sidebarfilter hidden" : "right-sidebarfilter show"}>
                    <div className="modal-header bg-primary rounded-0">
                        <h6 className="filter-modal-title"> Filter</h6>
                        <button onClick={this.openandclosefilter} type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" className="navbar-toggler right-sidebar-toggler pointer">×</span>
                        </button>
                    </div>
                    <div className="card-body">
                        <form>
                            <SrAsmDropDown
                                ondropdownChange={(e) => this.ondropdownsrasm("srsam", e)}
                                value={this.state.srsam}
                                placeholder="Sr. ASM"
                            />
                            <Userdropdown
                                ondropdownChange={this.ondropdownuser}
                                name="user"
                                value={this.state.user}
                                className="form-control-square "
                                type="User"
                                placeholder="User"
                                clear={true}
                            />
                        </form>
                    </div>
                    <div className="col-md-6 mb-4 offset-md-3 text-center">
                        <button onClick={this.loadResult} data-dismiss="modal" type="button" aria-hidden="true" className="btn btn-primary navbar-toggler right-sidebar-toggler pointer mr-1">Apply </button>
                        <button type="button" data-dismiss="modal" onClick={this.resetfilter} className="btn btn-outline-dark ">Reset</button>
                    </div>
                </div>
            </main>


        )
    }
}

function mapStateToProps(state) {
    return {
        srasmassignList_response: state.srasmassignList_response,
        srAsmAssign_delete_response: state.srAsmAssign_delete_response
    };
}

const mapDispatchToProps = dispatch => ({
    srasmassignList: data => dispatch(srasmassignList(data)),
    delete_srAsmAssign: item_id => dispatch(delete_srAsmAssign(item_id)),
});

SrAsmUserAssignment = connect(
    mapStateToProps,
    mapDispatchToProps
)(SrAsmUserAssignment);

export default SrAsmUserAssignment;